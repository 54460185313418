import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of } from 'rxjs';

import { IdObject } from '@campaign-portal/namespace/common/idObject';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { Channel } from '@campaign-portal/namespace/entities/channels/specs';
import { ContactGroup } from '@campaign-portal/namespace/entities/contact-groups/specs';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { MessageTemplate } from '@campaign-portal/namespace/entities/templates/specs';
import { CountriesService } from '@helpers/services/countries.service';
import { LinkDomain } from '@campaign-portal/namespace/entities/link-domains/specs';

import { ChannelsService } from '@helpers/services/channels.service';
import { ContactGroupsService } from '../../pages/contacts/services/contact-groups.service';
import { SendersService } from '../../pages/campaigns/services/senders.service';
import { MessageTemplatesService } from '../../pages/campaigns/services/message-templates.service';
import { ContactFieldsService } from '../../pages/contacts/services/contact-fields.service';
import { CountriesNetworksService } from '@helpers/services/countries-networks.service';
import { ApiKeysService } from '../../pages/api/api.service';
import { LinkDomainsService } from '../../pages/url-shortener/link-domains.service';

export enum RepoType {
	CHANNELS = 'ch',
	GROUPS = 'cGroup',
	DELETED_GROUPS = 'cDeletedGroup',
	SENDERS = 'sender',
	TEMPLATE = 'template',
	LINK_DOMAIN = 'linkDomains',
}

@Injectable({
	providedIn: 'root'
})
export class RepoService {

	public readonly loaded$ = new BehaviorSubject<boolean>(false);

	constructor(
		// with pipes:
		private readonly channelsService: ChannelsService,
		private readonly contactGroupsService: ContactGroupsService,
		private readonly sendersService: SendersService,
		private readonly templatesService: MessageTemplatesService,
		private readonly domainsService: LinkDomainsService,
		// just stores
		private readonly countriesNetworksService: CountriesNetworksService,
		private readonly contactFieldsService: ContactFieldsService,
		private readonly countriesService: CountriesService,
		private readonly apiService: ApiKeysService
	) {
	}

	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.CHANNELS): Channel | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.GROUPS | RepoType.DELETED_GROUPS): ContactGroup | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.SENDERS): SenderSubscription | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.TEMPLATE): MessageTemplate | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType.LINK_DOMAIN): LinkDomain | null;
	public transform(id: Id<exist> | IdObject<exist>, type: RepoType): IdObject | null {
		switch (type) {
			case RepoType.CHANNELS:
				return this.find<Channel>(id, this.channelsService.map);
			case RepoType.GROUPS:
				return this.find<ContactGroup>(id, this.contactGroupsService.map);
			case RepoType.DELETED_GROUPS:
				return this.find<ContactGroup>(id, this.contactGroupsService.removedMap$.getValue());
			case RepoType.SENDERS:
				return this.find<SenderSubscription>(id, this.sendersService.map);
			case RepoType.TEMPLATE:
				return this.find<MessageTemplate>(id, this.templatesService.map);
			case RepoType.LINK_DOMAIN:
				return this.find<LinkDomain>(id, this.domainsService.map);
			default:
				return null;
		}
	}

	public reset(): void {
		// this.loaded$.next(false);

		this.channelsService.clear();

		this.contactGroupsService.clear();

		this.contactGroupsService.removedMap$.next(new Map());

		this.sendersService.clear();

		this.templatesService.clear();

		this.countriesNetworksService.clear();

		this.contactFieldsService.clear();

		this.countriesService.clear();

		this.apiService.clear();

		this.domainsService.clear();

		this.loaded$.next(false);
	}

	public load(): Observable<unknown> {
		const repositoriesRequests: Observable<unknown>[] = [
			this.channelsService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),
			this.countriesNetworksService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),
			this.countriesService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),
			this.contactFieldsService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),
			this.contactGroupsService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),
			this.contactGroupsService.loadRemoved().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),
			this.sendersService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),
			this.templatesService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),
			this.domainsService.load().pipe(catchError((resp) => {
				resp.Data = [];
				return of(resp);
			})),
		];


		return forkJoin(repositoriesRequests).pipe(
			map((resp) => {
				this.loaded$.next(true);
				return resp;
			})
		);

	}

	private find<T>(
		ids: Id<exist> | IdObject<exist>,
		dataMap: Map<number, T>
	): T | null {
		if ( typeof ids === 'number' ) {
			const found = dataMap.get(ids);
			return found ?? null;
		} else if ( typeof ids === 'object' && !Array.isArray(ids) ) {
			const found = dataMap.get(ids.id);
			return found ?? null;
		}
		return null;
	}

}
