import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse,
	UpdateResponse
} from '@campaign-portal/namespace/common/implementations';
import { ContactField } from '@campaign-portal/namespace/entities/contact-fields/specs';
import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisProfileService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';
import { exist } from '@campaign-portal/namespace/common/id';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { CP_PERMISSIONS } from '@helpers/types/permissions';


@Injectable({
	providedIn: 'root'
})
export class ContactFieldsService extends ExtendableRefBookService<ContactField<exist>> implements AbstractCRUDService {

	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	readonly variables$ = new BehaviorSubject<string[]>([]);

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService,
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profileService: AlarisProfileService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.contactField');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.contactField');
	}

	override clear(): void {
		super.clear();
		this.variables$.next([]);
	}

	override load(): Observable<ReadResponse<ContactField<exist>[]>> {
		if ( this.profileService.allowed([CP_PERMISSIONS.CONTACTS_R]) ) {
			return this.api.loader<ReadResponse<ContactField<exist>[]>>(
				'ContactFields.Read', undefined, this.loading$, this.errorNotifier
			).pipe(map((resp) => {

				resp.Data.map((field) => {
					if ( field.variable === 'contactGroups' ) {
						field.property = 'id';
					}
				});

				this.list$.next(resp.Data || []);
				const variables: string[] = [];
				this.map$.next(this.list.reduce((result, field) => {
					variables.push(field.variable);
					result.set(field.id, field);
					return result;
				}, new Map()));
				this.variables$.next(variables);

				return resp;
			}));
		}
		return of({ Success: true, Total: 0, Data: [] });
	}

	read(params: RPCRequestParams): Observable<ReadResponse<ContactField[]>> {
		// todo ref$ check
		return this.api.loader<ReadResponse<ContactField[]>>(
			'ContactFields.Read', params, this.loading$, this.errorNotifier
		);
	}

	update(field: ContactField): Observable<CreateResponse<ContactField> | UpdateResponse<ContactField>> {
		const params: RPCRequestParams = {
			Data: {
				Entities: [
					field
				]
			}
		};
		const notify = (response: RPCResult<ContactField>): void => {
			const message = this.langService.translate(
				field.id ? 'notifications.actions.update' : 'notifications.actions.create', {
					entity: this.entity,
					name: field.name ?? ''
				});
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CreateResponse<ContactField> | UpdateResponse<ContactField>>(
			'ContactFields.' + (field.id === null ? 'Create' : 'Update'),
			params,
			this.loading$,
			this.errorNotifier,
			notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	delete(field: ContactField<exist>): Observable<DeleteResponse<ContactField>> {
		const params: DeleteRequest<ContactField<exist>> = {
			Data: {
				Ids: [
					field.id
				]
			}
		};
		const notify = (response: DeleteResponse<ContactField>): void => {
			const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<ContactField>>(
			'ContactFields.Delete',
			params,
			this.loading$,
			this.errorNotifier,
			notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

}

