import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Campaign } from '@campaign-portal/namespace/entities/campaigns/specs';
import { AlarisIconDirective, ChannelUtilsService, TOOLTIP_COMPONENT_DATA } from '@campaign-portal/components-library';
import { TranslateModule } from '@ngx-translate/core';
import { SenderPipe } from '@helpers/repo/repo.pipe';

@Component({
	selector: 'app-campaign-template-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		TranslateModule,
		AlarisIconDirective,
		SenderPipe
	],
	standalone: true
})
export class CampaignTemplateTooltipComponent {

	constructor(
		@Inject(TOOLTIP_COMPONENT_DATA) public readonly campaign: Campaign,
		public readonly cu: ChannelUtilsService
	) {
	}

}
