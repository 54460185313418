<div class="header">
	<div class="country">{{ 'columns.country' | translate }}</div>
	<div>{{ 'columns.network' | translate }}</div>
</div>
@for (country of countries; track country; let index = $index; let last = $last) {
	<div [class.no-divider]="last && countriesNetworks.size <= MAX_VISIBLE_COUNTRIES"
		 class="row">
		<b class="country">{{ country }}</b>
		<div class="networks">
			@for (network of getNetworks(country); track network) {
				<span [alarisTooltip]="network" [overflow]="true"
					  class="text-truncate">{{ network }}
				</span>
			}
			@if ((countriesNetworks.get(country)?.size ?? 0) > MAX_VISIBLE_NETWORKS) {
				<div (click)="toggleNetworksVisibility(country)" class="toggle toggle__network">
					<span>
						{{ 'purchase.networks' | translate }}:
						{{ (countriesNetworks.get(country)?.size ?? 0) - MAX_VISIBLE_NETWORKS }}
					</span>
					<alaris-icon [class.expanded]="expandedNetworks.get(country)"
								 class="icon" name="icon-arrow-select">
					</alaris-icon>
				</div>
			}
		</div>
	</div>
}
@if (countriesNetworks.size > MAX_VISIBLE_COUNTRIES) {
	<div (click)="toggleCountriesVisibility()" class="toggle toggle__country">
		<span>
			{{
				'purchase.showMore' | translate : {
					amount: countriesNetworks.size - MAX_VISIBLE_COUNTRIES
				}
			}}
		</span>
		<alaris-icon [class.expanded]="expandedCountries"
					 class="icon" name="icon-arrow-select">
		</alaris-icon>
	</div>
}
