import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { EmptyTableComponent } from '@helpers/empty-table/empty-table.component';

@Component({
	selector: 'app-empty-campaign-card',
	templateUrl: './empty-campaign-card.component.html',
	styleUrls: ['./empty-campaign-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AsyncPipe,
		EmptyTableComponent
	],
	standalone: true
})
export class EmptyCampaignCardComponent {
	@Input() loading$: Observable<boolean> = new BehaviorSubject<boolean>(true);
	@Input() hasSenders = false;
	@Input() hasSubscriptions = false;
	@Output() readonly senderRequest = new EventEmitter();
	@Output() readonly financeTransition = new EventEmitter();

	constructor() {
	}

}
