import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { exist } from '@campaign-portal/namespace/common/id';
import { Channel } from '@campaign-portal/namespace/entities/channels/specs';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import {
	AlarisApiService,
	AlarisLanguageService,
	ErrorNotifierConfig,
	ExtendableRefBookService
} from '@campaign-portal/components-library';
import { TrafficType } from '@campaign-portal/namespace/common/enums';


@Injectable({
	providedIn: 'root'
})
export class ChannelsService extends ExtendableRefBookService<Channel<exist>> {
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService
	) {
		super();
	}

	get title(): string {
		return this.langService.translate('notifications.titles.channel');
	}

	override load(): Observable<ReadResponse<Channel<exist>[]>> {
		return this.api.loader<ReadResponse<Channel<exist>[]>>(
			'Channels.Read', undefined, this.loading$, this.errorNotifier
		).pipe(
			map((resp) => {
				resp.Data = resp.Data.sort((a, b) => {
					const first = Object.keys(TrafficType).indexOf(a.channelType) || 0;
					const second = Object.keys(TrafficType).indexOf(b.channelType) || 0;
					return second - first;
				});
				return super.process(resp);
			})
		);
	}
}
