<alaris-card size="auto">
	<div [alarisLoading]="loading$" style="width: 100%;border-radius: 0.5rem 0.5rem 0 0;">
		<div class="head">
			<alaris-icon (click)="close()"
						 class="standard close" data-testid="closeAdditionalBtn"
						 name="deprecated/modal-close">
			</alaris-icon>
			<h3>
				@switch (data.type) {
					@case ('Delete') {
						{{ "campaigns.delete" | translate }}
					}
					@case ('Captcha') {
						{{ "campaigns.readyToSent" | translate }}
					}
				}
			</h3>
		</div>

		<div class="content">
			<div class="note">
				@switch (data.type) {
					@case ('Delete') {
						@if (campaign.info.status === campaignStatus.SCHEDULED) {
							<p>
								{{ 'campaigns.deleteScheduledNote' | translate }}
								{{ campaign.info.scheduled | date: 'd MMM y, HH:mm' }}
							</p>
						}
						<p>{{ 'campaigns.deleteNote' | translate }}</p>
					}
					@case ('Captcha') {
						<label>{{ 'campaigns.campaign' | translate }}</label>
						<ul>
							<li>{{ 'campaigns.name' | translate }}:
								{{ campaign.info.name ? campaign.info.name : '-' }}
							</li>
							<li>
								{{ 'campaigns.scheduledDate' | translate }}
								: {{ (campaign.info.scheduled | date: 'd MMM y, HH:mm') || ('gl.now' | translate) }}
							</li>
							<li>{{ 'campaigns.recipients' | translate }}: {{ total }}</li>
						</ul>

						<alaris-text-area [formControl]="description" data-testid="descriptionTextarea">
							{{ 'campaigns.description' | translate }}
						</alaris-text-area>
					}
				}
			</div>
			@if (data.type === 'Captcha') {
				<div class="details">
					<p>{{ 'gl.completeCaptcha' | translate }}</p>
					<alaris-captcha (key)="captcha.controls.key.setValue($event)"
									[forceUpdate$]="updateCaptcha$"
									[formControl]="captcha.controls.answer" data-testid="captcha">
					</alaris-captcha>
				</div>
			}

		</div>

		<div class="footer">
			<button (click)="close()"
					[disabled]="loading$.getValue()"
					alaris-button
					bType="outline" data-testid="closeBtn">
				{{ 'actions.close' | translate }}
			</button>
			<button (click)="confirm()"
					[disabled]="disabled"
					alaris-button
					bType="primary" data-testid="closeBtn">
				{{ 'actions.confirm' | translate }}
			</button>
		</div>
	</div>
</alaris-card>

