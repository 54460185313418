import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, Subject, tap } from 'rxjs';

import {
	ClientSubscriptionsReadResponse,
	Package,
	Plan,
	RequestSubscriptionRequest,
	SubscribeRequest,
	UnsubscribeRequest
} from '@campaign-portal/namespace/entities/subscriptions/specs';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { UpdateResponse } from '@campaign-portal/namespace/common/implementations';
import { Id } from '@campaign-portal/namespace/common/id';

import {
	AlarisApiService,
	AlarisDialogService,
	AlarisLanguageService,
	AlarisProfileService,
	AlarisProgressLineType,
	AlarisToasterService,
	ErrorNotifierConfig,
	PROFILE_SERVICE_INJECTOR
} from '@campaign-portal/components-library';
import { PurchaseDialogsComponent, PurchaseDialogType, PurchaseHintDialogType } from './dialogs/dialogs.component';
import { ComponentType } from '@angular/cdk/overlay';
import { AllCountriesDialogComponent } from './dialogs/all-countries-dialog/all-countries-dialog.component';
import { catchError } from 'rxjs/operators';
import { CP_PERMISSIONS } from '@helpers/types/permissions';

export type SubscribedPackagesStore = Map<Id, [Package, ...Package[]]>;

@Injectable({
	providedIn: 'root'
})
export class PurchaseService {

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly loadingRead$ = new BehaviorSubject<boolean>(false);
	readonly loadingSubscribed$ = new BehaviorSubject<boolean>(false);
	readonly update$ = new Subject();

	subscribedPackages: SubscribedPackagesStore = new Map();
	subscribedPlans: Plan[] = [];

	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly dialog: AlarisDialogService,
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profile: AlarisProfileService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.purchase');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.purchase');
	}

	static calcProgress(packs: Package[]): [used: number, total: number, locked: number] {
		return packs.reduce((res, pack) => {
			res[0] = (pack.messagesUsed ?? 0) + res[0];
			res[1] = pack.messagesTotal + res[1];
			res[2] = (pack.messagesLocked ?? 0) + res[2];
			return res;
		}, [0, 0, 0]);
	}

	static packUsedState(rest: number, total: number): AlarisProgressLineType {
		const progress = (rest / total) * 100;
		return progress > 70 && progress <= 100
			? 'success'
			: progress <= 70 && progress > 50
				? 'warning'
				: progress <= 50
					? 'negative'
					: 'success';
	}

	request(params: RequestSubscriptionRequest): Observable<RPCResult<void>> {
		return this.api.loader<RPCResult<void>>(
			'Subscriptions.Request',
			params,
			this.loading$,
			this.errorNotifier,
			this.prepareNotification('request')
		);
	}

	read(params?: RPCRequestParams): Observable<ClientSubscriptionsReadResponse> {
		return this.api.loader<ClientSubscriptionsReadResponse>(
			'Subscriptions.Read', params, this.loadingRead$, this.errorNotifier
		);
	}

	subscribed(params?: RPCRequestParams): Observable<{
		Data: {
			packs: SubscribedPackagesStore;
			plans: Plan[];
		};
	}> {
		let request: Observable<ClientSubscriptionsReadResponse> = of({
			Success: true,
			Total: 0,
			Data: { packs: [], plans: [] }
		});

		if ( this.profile.allowed([CP_PERMISSIONS.SUBSCR_R]) ) {
			request = this.api.loader<ClientSubscriptionsReadResponse>(
				'Subscriptions.Subscribed', params, this.loadingSubscribed$, this.errorNotifier
			);
		}

		return request.pipe(
			map(
				(resp) => {
					this.subscribedPackages = this.storePacks(resp.Data.packs);
					this.subscribedPlans = resp.Data.plans;
					return {
						Data: {
							plans: resp.Data.plans,
							packs: this.subscribedPackages
						}
					};
				}
			),
			catchError((resp) => {
				resp.Data = {
					plans: [],
					packs: new Map()
				};
				return of(resp);
			})
		);
	}

	subscribe(params: SubscribeRequest): Observable<UpdateResponse<Package | Plan>> {
		return this.api.loader<UpdateResponse<Package | Plan>>(
			'Subscriptions.Subscribe', params, this.loading$, this.errorNotifier, this.prepareNotification('subscribe')
		);
	}

	unsubscribe(params: UnsubscribeRequest): Observable<UpdateResponse<Package | Plan>> {
		return this.api.loader<UpdateResponse<Package | Plan>>(
			'Subscriptions.Unsubscribe', params, this.loading$,
			this.errorNotifier, this.prepareNotification('unsubscribe')
		);
	}

	openSpecificHintDialog(type: PurchaseHintDialogType, item: Plan | Package): void {
		let component: ComponentType<unknown>;
		switch (type) {
			case 'AllCountries':
				component = AllCountriesDialogComponent;
				break;
		}

		this.dialog.open(component, {
			data: { item },
			autoFocus: '__no_exist_element__'
		});
	}

	openSpecificDialog(
		type: PurchaseDialogType,
		item: { plan?: Plan; package?: Package } | null = null
	): Observable<unknown> {
		const subscrItemObj = item !== null ? { package: item.package ?? null, plan: item.plan ?? null } : {};
		return this.dialog.open(PurchaseDialogsComponent, {
			data: { type, ...subscrItemObj },
			autoFocus: false
		}).closed.pipe(
			tap(result => {
				if ( result ) {
					this.update$.next(result);
				}
				return result;
			}));
	}

	getActiveTillDate(pack: Package): string {
		const subscriptionDate = pack.creationDate;
		const validityPeriodByDay = pack.packSettings.validityPeriodByDay;
		if ( subscriptionDate && validityPeriodByDay ) {
			const date = new Date(subscriptionDate);
			return new Date(date.setDate(date.getDate() + validityPeriodByDay)).toISOString();
		}
		return '';
	}

	isExpireDateClose(pack: Package): boolean {
		const activeTill = this.getActiveTillDate(pack);
		if ( !activeTill ) {
			return false;
		}
		return Math.round((Date.parse(activeTill) - Date.now()) / (1000 * 3600 * 24)) < 30;
	}

	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	private storePacks(packs: Package[]): SubscribedPackagesStore {
		const store: SubscribedPackagesStore = new Map();
		packs.forEach((pack) => {
			if ( !pack.subscriptionId ) {
				return;
			}
			if ( store.has(pack.subscriptionId) ) {
				store.get(pack.subscriptionId)?.push(pack);
			} else {
				store.set(pack.subscriptionId, [pack]);
			}
		});
		return store;
	}

	private prepareNotification(type: 'subscribe' | 'unsubscribe' | 'request'): (response: RPCResult<unknown>) => void {
		let message: string;
		switch (type) {
			case 'subscribe':
				message = this.langService.translate('notifications.actions.subscribe', { entity: this.entity });
				break;
			case 'unsubscribe':
				message = this.langService.translate('notifications.actions.unsubscribe', { entity: this.entity });
				break;
			case 'request':
				message = this.langService.translate('notifications.actions.request', { entity: this.entity });
				break;
			default:
				message = this.langService.translate('enums.SUCCESS');
		}
		return (response: RPCResult<unknown>): void => {
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
	}

}
