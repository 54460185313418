import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	inject,
	Input,
	OnInit
} from '@angular/core';

import {
	AlarisIconDirective,
	AlarisLanguageService,
	AlarisTooltipDirective
} from '@campaign-portal/components-library';
import { Package } from '@campaign-portal/namespace/entities/subscriptions/specs';

import { CountriesNetworksService } from '@helpers/services/countries-networks.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';

const MAX_VISIBLE_COUNTRIES = 3;
const MAX_VISIBLE_NETWORKS = 2;

@Component({
	selector: 'app-package-country-network-list',
	templateUrl: './package-country-network-list.component.html',
	styleUrl: './package-country-network-list.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		TranslateModule,
		AlarisTooltipDirective,
		AlarisIconDirective
	],
	standalone: true
})
export class PackageCountryNetworkListComponent implements OnInit {
	@Input({ required: true }) package!: Package;

	readonly MAX_VISIBLE_COUNTRIES = MAX_VISIBLE_COUNTRIES;
	readonly MAX_VISIBLE_NETWORKS = MAX_VISIBLE_NETWORKS;

	readonly countriesNetworks: Map<string, Set<string>> = new Map();
	readonly expandedNetworks: Map<string, boolean> = new Map();
	expandedCountries = false;
	private readonly destroyRef = inject(DestroyRef);

	constructor(
		private readonly countriesNetworksService: CountriesNetworksService,
		private readonly ls: AlarisLanguageService,
		private readonly cd: ChangeDetectorRef
	) {
	}

	get countries(): string[] {
		const countries = [...this.countriesNetworks.keys()];
		return this.expandedCountries ? countries : countries.slice(0, MAX_VISIBLE_COUNTRIES);
	}

	getNetworks(country: string): string[] {
		const networks = [...this.countriesNetworks.get(country) ?? []];
		const expanded = this.expandedNetworks.get(country);
		return expanded ? networks : networks.slice(0, MAX_VISIBLE_NETWORKS);
	}

	toggleNetworksVisibility(country: string): void {
		const expanded = this.expandedNetworks.get(country);
		this.expandedNetworks.set(country, !expanded);
	}

	toggleCountriesVisibility(): void {
		this.expandedCountries = !this.expandedCountries;
	}

	ngOnInit(): void {
		this.countriesNetworksService.map$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.init();
				this.cd.detectChanges();
			});
	}

	private init(): void {
		this.package.availableCountryNetList?.forEach(id => {
			const countryNetworkRef = this.countriesNetworksService.map.get(id);
			if ( !countryNetworkRef ) {
				return;
			}
			let { country, network } = countryNetworkRef;
			network = network ?? this.ls.translate('purchase.allNetworks');
			if ( this.countriesNetworks.has(country) ) {
				this.countriesNetworks.get(country)?.add(network);
			} else {
				this.countriesNetworks.set(country, new Set([network]));
			}
		});
	}
}
