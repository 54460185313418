import { Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ActivateGuard } from './auth/activate/activate.guard';
import { ResetGuard } from './auth/reset/reset.guard';
import { DropAuthGuard } from './auth/drop-auth.guard';
import { GoHomeComponent } from './layouts/go-home.component';
import { routerLinks } from '@helpers/shared/router-links.const';


export const APP_ROUTES: Routes = [
	{
		path: 'sign-in',
		loadComponent: () =>
			import('./layouts/sign-in-layout/sign-in-layout.component')
				.then(m => m.SignInLayoutComponent),
		canActivate: [DropAuthGuard],
		data: { animationState: 'SignInLayoutComponent' }
	},
	{
		path: 'sign-up',
		loadComponent: () =>
			import('./layouts/sign-up-layout/sign-up-layout.component')
				.then(m => m.SignUpLayoutComponent),
		canActivate: [DropAuthGuard],
		data: { animationState: 'SignUpLayoutComponent' }
	},
	{
		path: 'activate',
		loadComponent: () =>
			import('./auth/activate/activate.component')
				.then(m => m.ActivateComponent),
		canActivate: [DropAuthGuard, ActivateGuard]
	},
	{
		path: 'reset',
		canActivate: [DropAuthGuard, ResetGuard],
		loadComponent: () =>
			import('./auth/reset/reset.component')
				.then(m => m.ResetComponent)
	},
	{
		path: '',
		loadChildren: () =>
			import('./app.routing')
				.then(m => m.PAGES_ROUTES)
	},
	{ path: '**', redirectTo: '' }
];

export const PAGES_ROUTES: Routes = [{
	path: '',
	component: MainLayoutComponent,
	canActivate: [AuthGuard],
	canActivateChild: [AuthGuard],
	data: { animationState: 'MainLayoutComponent' },
	children: [
		{
			path: '',
			component: GoHomeComponent
		},
		...Object.values(routerLinks)
	]
}];
