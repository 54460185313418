<alaris-dialog [closeAction]="close.bind(this)"
			   [confirmAction]="copy.bind(this)"
			   [confirmButtonIcon]="'icon-copy-simple'"
			   [confirmButtonText]="(copied$ | async) ? 'gl.copied' : 'actions.copy'">
	<h3 class="header" slot="header">
		{{ 'api.dialogTitle' | translate: { name } }}
	</h3>
	<div class="content" slot="content">
		<div class="content" slot="content">
			<div class="token">{{ token }}</div>
			<span [innerHTML]="'api.dialogTokenNote' | translate" class="note"></span>
		</div>
	</div>
</alaris-dialog>
