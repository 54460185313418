import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivateUserRequest, ActivateUserResponse } from '@campaign-portal/namespace/entities/auth/specs';
import { AlarisApiService, AlarisLanguageService, ErrorNotifierConfig } from '@campaign-portal/components-library';

@Injectable({
	providedIn: 'root'
})
export class ActivateService {

	token = '';
	readonly loading$ = new BehaviorSubject(false);
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get title(): string {
		return this.langService.translate('notifications.titles.auth');
	}

	activate(): Observable<ActivateUserResponse> {
		const params: ActivateUserRequest = {
			Token: this.token
		};
		return this.api.loader<ActivateUserResponse>(
			'Auth.ActivateUser',
			params, this.loading$, this.errorNotifier
		);
	}
}
