import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { AlarisDialogComponent } from '@campaign-portal/components-library';


@Component({
	selector: 'app-lost-data-dialog',
	templateUrl: './lost-data-dialog.component.html',
	styleUrls: ['./lost-data-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		TranslateModule,
		AlarisDialogComponent
	],
	standalone: true
})
export class LostDataDialogComponent {

	constructor(
		private dialogRef: DialogRef<unknown>
	) {
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.dialogRef.close(true);
	}
}
