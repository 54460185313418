import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Campaign } from '@campaign-portal/namespace/entities/campaigns/specs';
import { AlarisIconDirective, ChannelUtilsService } from '@campaign-portal/components-library';

import { CampaignsService } from '../../../services/campaigns.service';
import { AvatarComponent } from './avatar/avatar.component';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-campaign',
	templateUrl: './campaign.component.html',
	styleUrls: ['./campaign.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AvatarComponent,
		TranslateModule,
		AlarisIconDirective,
		DatePipe
	],
	standalone: true
})
export class CampaignComponent {

	@Input() campaign!: Campaign;

	constructor(
		public readonly cu: ChannelUtilsService,
		public readonly campaignsService: CampaignsService
	) {
	}

}
