@if (campaign.setup.native) {
	@if (campaign.setup.native.contacts.file) {
		<div (click)="export(campaign.setup.native.contacts.file.id, campaign.setup.native.contacts.file.name)"
			 class="file text-truncate" data-testid="exportRecipientBtn">
			<alaris-icon name="icon-attach"></alaris-icon>
			{{ campaign.setup.native.contacts.file.name }} {{ formatBytes(campaign.setup.native.contacts.file.size) }}
		</div>
	}
	@if (campaign.setup.native.contacts.plain) {
		<span class="text-truncate">
			{{ 'gl.total' | translate }}: {{ campaign.setup.native.contacts.plain.text.split(',').length }}
		</span>
	}
	@if (campaign.setup.native.contacts.groups) {
		@for (cg of campaign.setup.native.contacts.groups; let i = $index; track i) {
			<div>
				@if (i < 1 && (contactGroups.list$ | async)?.length) {
					<alaris-tag class="text-truncate" size="md">
						{{ (cg | cGroup)?.name ?? '' | translate }}
					</alaris-tag>
				}
			</div>
		}

		@if (campaign.setup.native.contacts.groups.length > 1) {
			<div class="more text-truncate">
				+ {{ campaign.setup.native.contacts.groups.length - 1 }} more
			</div>
		}
	}
}
@if (campaign.setup.file) {
	@if (campaign.setup.file) {
		<div (click)="export(campaign.setup.file.file.id, campaign.setup.file.file.name)"
			 class="file text-truncate" data-testid="exportRecipientBtn">
			<alaris-icon name="icon-attach"></alaris-icon>
			{{ campaign.setup.file.file.name }}
		</div>
	}
}
