import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { ShortLink } from '@campaign-portal/namespace/entities/short-links/specs';
import { LinkDomain } from '@campaign-portal/namespace/entities/link-domains/specs';
import { exist } from '@campaign-portal/namespace/common/id';
import { CampaignShortLink } from '@campaign-portal/namespace/entities/campaigns/specs';
import { BehaviorSubject, Subject } from 'rxjs';
import { SHORT_LINK_PATTERN } from '@helpers/utils/template-message.serializer';

export interface ShortLinkEntry {
	host: string,
	full: string // [host](longUrl) format
}

@Injectable({
	providedIn: 'root'
})
export class UrlShortenerUtilsService {
	public readonly selectedForm = new FormGroup({
		link: new FormControl<ShortLink | null>(null),
		domain: new FormControl<LinkDomain<exist> | null>(null)
	});

	public readonly predefinedLink$ = new BehaviorSubject<CampaignShortLink | null>(null);
	public readonly refreshLinkList$ = new Subject<void>();

	constructor(
		private readonly router: Router
	) {
	}

	get selectedLink(): ShortLink | null {
		return this.selectedForm.value.link ?? null;
	}

	get selectedDomain(): LinkDomain<exist> | null {
		return this.selectedForm.value.domain ?? null;
	}

	selectLink(link: ShortLink): void {
		this.selectedForm.setValue({
			domain: null,
			link
		});
	}

	selectDomain(domain: LinkDomain<exist>): void {
		this.selectedForm.setValue({
			domain,
			link: null
		});
	}

	extractShortLink(message: string): ShortLinkEntry | undefined {
		const match = [...message.matchAll(SHORT_LINK_PATTERN())].at(0);

		return match
			? { full: match[0], host: match.groups?.domain ?? '' }
			: undefined;
	}

	navigateToShortener(link: CampaignShortLink): void {
		if ( this.router.url.includes('url-shortener') ) {
			return;
		}
		this.predefinedLink$.next(link);
		this.router.navigateByUrl(
			`url-shortener?shortenerTab=${link.isArchived ? 'archive' : 'domains'}`
		).then();
	}
}
