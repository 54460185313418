import { Inject, Injectable } from '@angular/core';
import { map, Observable, of, share, tap } from 'rxjs';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { exist } from '@campaign-portal/namespace/common/id';
import {
	MessageTemplate,
	TemplatesCheckCampaignUsageRequest,
	TemplatesCheckCampaignUsageResponse
} from '@campaign-portal/namespace/entities/templates/specs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisLanguageService,
	AlarisProfileService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService,
	PROFILE_SERVICE_INJECTOR,
	sortData
} from '@campaign-portal/components-library';
import { CP_PERMISSIONS } from '@helpers/types/permissions';


@Injectable({
	providedIn: 'root'
})
export class MessageTemplatesService extends ExtendableRefBookService<MessageTemplate<exist>> implements AbstractCRUDService {
	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService,
		@Inject(PROFILE_SERVICE_INJECTOR) private readonly profileService: AlarisProfileService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.template');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.template');
	}

	override load(): Observable<ReadResponse<MessageTemplate<number>[]>> {
		if ( this.profileService.allowed([CP_PERMISSIONS.MESSAGE_TEMPLATES_R]) ) {
			return this.api.loader<ReadResponse<MessageTemplate<exist>[]>>(
				'Templates.Read', undefined, this.loading$, this.errorNotifier
			)
				.pipe(map((resp) => {
					sortData(resp.Data, 'name');
					return super.process(resp);
				}));
		}
		return of({ Success: true, Total: 0, Data: [] });
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<MessageTemplate<exist>[]>> {
		return this.api.loader<ReadResponse<MessageTemplate<exist>[]>>(
			'Templates.Read', params, this.loading$, this.errorNotifier
		);
	}

	update(template: MessageTemplate): Observable<CreateResponse<[MessageTemplate<exist>]>> {
		const notify = (response: CreateResponse<[MessageTemplate<exist>]>): void => {
			const message = this.langService.translate(
				template.id ? 'notifications.actions.update' : 'notifications.actions.create', {
					entity: this.entity,
					name: template.name ?? ''
				});
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		const observer = this.api.loader<CreateResponse<[MessageTemplate<exist>]>>(
			`Templates.${template.id ? 'Update' : 'Create'}`,
			{ Data: { Entities: [template] } }, this.loading$, this.errorNotifier, notify)
			.pipe(
				tap(() => {
					this.refresh$.next();
				}),
				share()
			);

		observer.subscribe();

		return observer;
	}

	delete(template: MessageTemplate<exist>): Observable<DeleteResponse<MessageTemplate>> {
		const params: DeleteRequest<MessageTemplate<exist>> = { Data: { Ids: [template.id] } };
		const notify = (response: DeleteResponse<MessageTemplate>): void => {
			const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		const observer = this.api.loader<DeleteResponse<MessageTemplate>>(
			'Templates.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(
			tap(() => {
				this.refresh$.next();
			}),
			share()
		);
		observer.subscribe();
		return observer;
	}

	checkCampaignUsage(template: MessageTemplate<exist>): Observable<TemplatesCheckCampaignUsageResponse> {
		const params: TemplatesCheckCampaignUsageRequest = {
			Data: {
				Ids: [template.id]
			}
		};
		return this.api.loader<TemplatesCheckCampaignUsageResponse>(
			'Templates.CheckCampaignUsage', params, this.loading$, this.errorNotifier
		);
	}
}
