import { Injectable } from '@angular/core';
import {
	AlarisApiService,
	AlarisLanguageService,
	AlarisProfileService,
	ErrorNotifierConfig,
	ExtendableRefBookService
} from '@campaign-portal/components-library';
import { exist } from '@campaign-portal/namespace/common/id';
import { LinkDomain } from '@campaign-portal/namespace/entities/link-domains/specs';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { LinkDomainStatus } from '@campaign-portal/namespace/common/enums';
import { RPCRequestParams, SortDirection } from '@campaign-portal/namespace/common/rpc.params';
import { CP_PERMISSIONS } from '@helpers/types/permissions';

@Injectable({
	providedIn: 'root'
})
export class LinkDomainsService extends ExtendableRefBookService<LinkDomain<exist>> {

	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	active$ = new BehaviorSubject<LinkDomain<exist>[]>([]);

	constructor(
		private readonly profile: AlarisProfileService,
		private readonly api: AlarisApiService,
		private readonly langService: AlarisLanguageService
	) {
		super();
	}

	get title(): string {
		return this.langService.translate('notifications.titles.shortDomains');
	}

	override load(): Observable<ReadResponse<LinkDomain<exist>[]>> {
		if ( !this.profile.allowed([CP_PERMISSIONS.URL_SHORTENER_E]) ) {
			return of({
				Success: true,
				Total: 0,
				Data: []
			});
		}

		const params: RPCRequestParams = {
			Sorting: [{ Field: 'creationDate', Dir: SortDirection.DESC }]
		};
		return this.api.loader<ReadResponse<LinkDomain<exist>[]>>(
			'LinkDomains.Read', params,
			this.loading$, this.errorNotifier
		)
			.pipe(
				map((resp) => {
					const active = resp.Data.filter(domain => {
						return domain.status === LinkDomainStatus.ACTIVE;
					});
					this.active$.next(active);
					return super.process(resp);
				})
			);
	}
}
