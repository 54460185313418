@if (campaign.setup.channels.length > 1) {
	<div [alarisComplexTooltip]="CampaignTemplateTooltipComponent"
		 [componentData]="campaign" class="sender" style="cursor: pointer;">
		<ng-container *ngTemplateOutlet="sender"></ng-container>
	</div>
}
@if (campaign.setup.channels.length === 1) {
	<div class="sender">
		<ng-container *ngTemplateOutlet="sender"></ng-container>
	</div>
}
@if (campaign.setup.native) {
	@let text = campaign.setup.channels[0].message?.text;
	<div [alarisTooltip]="text ?? ''" [overflow]="true" class="message">
		{{ text ?? '' }}
	</div>
}
@if (campaign.setup.file) {
	@if (campaign.setup.file) {
		<div (click)="export(campaign.setup.file.file.id, campaign.setup.file.file.name)"
			 class="file text-truncate" data-testid="exportBtn">
			<alaris-icon name="icon-attach"></alaris-icon>
			{{ campaign.setup.file.file.name }} {{ formatBytes(campaign.setup.file.file.size) }}
		</div>
	}
}

<ng-template #sender>
	<alaris-icon name="round-mark"></alaris-icon>
	<div class="text-truncate">
		{{ (campaign.setup.channels[0].sender | sender)?.name }}
	</div>
</ng-template>
