import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { CountryPhoneCode } from '@campaign-portal/namespace/entities/countries/specs';
import { ExtendableRefBookService } from '@campaign-portal/components-library';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';

@Injectable({
	providedIn: 'root'
})
export class CountriesService extends ExtendableRefBookService<CountryPhoneCode> {

	constructor() {
		super();
	}

	override load(): Observable<ReadResponse<CountryPhoneCode[]>> {
		this.loading$.next(true);
		return from(
			fetch('./assets/data/country_codes.json')
				.then(response => response.json())
				.then((resp: CountryPhoneCode[]) => {
					const result: ReadResponse<CountryPhoneCode[]> = {
						Total: resp.length,
						Success: true,
						Data: resp
					};
					return this.process(result);
				})
		);
	}

	override process(resp: ReadResponse<CountryPhoneCode[]>): ReadResponse<CountryPhoneCode[]> {
		this.list$.next(resp.Data);
		this.map$.next(this.list.reduce((result, item) => {
			result.set(item.code, item);
			return result;
		}, new Map()));
		this.loading$.next(false);
		return resp;
	}

}
