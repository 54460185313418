<div [@layoutChanged]="prepareRoute(outlet)">
	<router-outlet #outlet="outlet"></router-outlet>
</div>
@if (!environment.production) {
	<div class="version-container">
		<div class="version">
			{{ environment.version }}
		</div>
	</div>
}

