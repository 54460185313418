<div class="campaign">

	<div class="title">
		<div class="name">
			@if (campaign.info.photo) {
				<app-campaign-list-avatar [campaign]="campaign"></app-campaign-list-avatar>
			}
			<label>{{ 'campaigns.campaignName' | translate }}</label>
			<h3>{{ campaign.info.name }}</h3>
			<label>{{ campaign.info.description }}</label>
		</div>
		<div *alarisPBAC="[CP_PERMISSIONS.CAMPAIGNS_E]" class="actions">
			@if (!(campaign.setup.hasOwnProperty('file') && campaign.info.status === CampaignStatus.COMPLETED)) {
				<button (click)="action(campaign)" alarisButton bType="primary" data-testid="mainActionBtn" size="md">
					@switch (campaign.info.status) {
						@case (CampaignStatus.SCHEDULED) {
							<alaris-icon class="in-primary" name="icon-play"></alaris-icon>
							{{ 'actions.start' | translate }}
						}
						@case (CampaignStatus.PAUSED) {
							<alaris-icon class="in-primary" name="icon-play"></alaris-icon>
							{{ 'actions.resume' | translate }}
						}
						@case (CampaignStatus.IN_PROGRESS) {
							<alaris-icon class="in-primary" name="icon-pause-circle"></alaris-icon>
							{{ 'actions.pause' | translate }}
						}
						@default {
							@if (campaign.setup.hasOwnProperty('file')) {
								<alaris-icon class="in-primary" name="icon-delete"></alaris-icon>
								{{ 'actions.delete' | translate }}
							} @else {
								<alaris-icon class="in-primary" name="icon-arrow-move-list"></alaris-icon>
								{{ 'actions.repeat' | translate }}
							}
						}
					}
				</button>
			}
			<div [class.opened]="isOpened" class="more-actions">
				<alaris-icon (isOpened)="isOpened = $event"
							 [alarisDropdown]="dropdown"
							 [positions]="position"
							 data-testid="moreActionsDropdownToggle" name="icon-dot-horizontal">
				</alaris-icon>
			</div>
			<alaris-dropdown #dropdown>
				<alaris-dropdown-actions [actions]="actions" [row]="campaign"
										 data-testid="dropdownActions">
				</alaris-dropdown-actions>
			</alaris-dropdown>
		</div>
	</div>

	<div class="progress">
		<div class="channels">
			<label>{{ 'gl.channel' | translate }}</label>
			<div>
				<ng-container *ngTemplateOutlet="
					campaignChannelsTemp;
					context: {channels: campaign.setup.channels}">
				</ng-container>
				<ng-template #campaignChannelsTemp let-channels="channels">
					@for (channel of channels; track $index) {
						<alaris-icon [name]="cu.icon(channel.trafficType)"
									 class="channel-icon"
									 state="simple">
						</alaris-icon>
					}
					{{ channels.length === 1 ? channels[0]?.trafficType : 'campaigns.cascade' | translate }}
				</ng-template>
			</div>
		</div>

		<div class="status">
			<label>{{ 'gl.status' | translate }}</label>
			<alaris-tag [mode]="campaignUtils.selectStatusColor(campaign.info.status)"
						[rounded]="true"
						class="text-truncate">
				{{ 'enums.' + campaign.info.status | translate }}
			</alaris-tag>
		</div>

		@if (campaign.info.status === CampaignStatus.IN_PROGRESS || campaign.info.status === CampaignStatus.PAUSED) {
			<div class="bar">
				<label>{{ 'gl.progress' | translate }}</label>
				<alaris-progress-line [content]="true"
									  [percentageComplete]="campaignUtils.calculatePercentage(campaign)"
									  [type]="campaignUtils.selectCampaignProgressColor(campaign)">
					<label class="progress-label">
						{{
							campaignUtils.calculatePercentage(campaign) ?
								(campaignUtils.calculatePercentage(campaign) | number: '1.0-1') + '%' :
								('gl.notAvailable' | translate)
						}}
					</label>
				</alaris-progress-line>
			</div>
		}
		<div class="amount">
			<label class="status text-truncate">{{ 'campaigns.amountSpent' | translate }}</label>
			{{ campaign.statistics?.cost  | currency: bs.currencyCode }}
		</div>
	</div>

	<div class="info">
		<div class="contacts">
			@if (campaign.setup.native) {
				<label>{{ 'contacts.title' | translate }}</label>
				@if (campaign.setup.native.contacts!.groups !== undefined) {
					<div>
						@if (campaign.setup.native.contacts!.groups !== null) {
							@for (groupId of campaign.setup.native.contacts!.groups; track groupId) {
								<alaris-tag class="max-width" mode="gray" size="lg">
									{{ (groupId | cGroup)?.name ?? '' | translate }}
									<label>{{ (groupId | cGroup)?.contactsIncluded }}</label>
								</alaris-tag>
							}
						} @else {
							<alaris-tag mode="gray" size="lg">{{ 'gl.all' | translate }}</alaris-tag>
						}
					</div>
				}
				@if (campaign.setup.native.contacts!.file !== undefined) {
					<div
						(click)="export(campaign.setup.native!.contacts!.file!.id, campaign.setup.native!.contacts!.file!.name)"
						class="file text-truncate" data-testid="exportBtn">
						<alaris-icon name="icon-attach"></alaris-icon>
						{{ campaign.setup.native!.contacts!.file!.name }} {{ formatBytes(campaign.setup.native!.contacts!.file!.size) }}
					</div>
				}
				@if (campaign.setup.native.contacts!.plain !== undefined) {
					<div (isOpened)="plainContactsOpened = $event"
						 [alarisDropdown]="contactsDropDown"
						 [class.opened]="plainContactsOpened" class="plain" data-testid="contactsDropdownToggle">
						{{ campaign.setup.native!.contacts!.plain!.text.split(CONTACTS_SPLIT_PATTERN).length }}
						<alaris-icon [name]="plainContactsOpened ? 'icon-caret-up' : 'icon-caret-down'"></alaris-icon>
						<alaris-dropdown #contactsDropDown [closeOnClick]="false">
							<div class="dropdown-container">
								@for (contact of
									campaign.setup.native!.contacts!.plain!.text.split(CONTACTS_SPLIT_PATTERN);
									track contact; let i = $index; ) {
									<div>{{ i + 1 }}. {{ contact }}</div>
								}
							</div>
						</alaris-dropdown>
					</div>
				}
			}

			@if (campaign.setup.file) {
				<div (click)="export(campaign.setup.file!.file!.id, campaign.setup.file!.file!.name)"
					 class="file text-truncate" data-testid="exportBtn">
					<alaris-icon name="icon-attach"></alaris-icon>
					{{ campaign.setup.file!.file!.name }} {{ formatBytes(campaign.setup.file!.file!.size) }}
				</div>
			}
		</div>

		<div class="short-url">
			@if (hasShortUrlSection) {
				<label>{{ 'urlShortener.shortURL' | translate }}</label>
				@let shortLink = campaign.info.shortLink ;
				@if (shortLink) {
					<alaris-link (click)="navigateToShortener()"
								 [alarisTooltip]="shortLink.isArchived
								 	? ('urlShortener.urlBeenArchived' | translate) : ''"
								 [underline]="false" [attr.data-testid]="'shortLink/' + campaign.id"
								 [class.disabled]="shortLink.isArchived">
						{{ shortLink.shortUrl }}
					</alaris-link>
				} @else {
					<label>{{ 'urlShortener.noStatistics' | translate }}</label>
				}
			}
		</div>
		
		<div class="dates">
			@if (campaign.audit) {
				<div>
					<label>{{ 'campaigns.createdOn' | translate }}</label>
					<span>{{ campaign.audit.creationDate | date: 'd MMM y, HH:mm' }}</span>
				</div>
			}
			@if (campaign.info.status === CampaignStatus.SCHEDULED) {
				<div>
					<label>{{ 'campaigns.scheduledDate' | translate }}</label>
					<span>{{ campaign.info.scheduled | date: 'd MMM y, HH:mm' }}</span>
				</div>
			}
			@if (campaign.info.startDate) {
				<div>
					<label class="label">{{ 'campaigns.startDate' | translate }}</label>
					<span>{{ campaign.info.startDate | date: 'd MMM y, HH:mm' }}</span>
				</div>
			}
			@if (campaign.info.finishedDate) {
				<div>
					<label class="label">{{ 'campaigns.endDate' | translate }}</label>
					<span>{{ campaign.info.finishedDate | date: 'd MMM y, HH:mm' }}</span>
				</div>
			}
			@if (campaign.audit) {
				<div>
					<label>{{ 'campaigns.createdBy' | translate }}</label>
					<span class="cut">{{ campaign.audit.createdBy }}</span>
				</div>
			}
		</div>
	</div>

	<div class="stats">
		<h6>{{ 'campaigns.campaignStatistics' | translate }}</h6>
		<div class="content">
			<div class="card">
				<label>
					<span>
						{{
							(campaign.info.status === CampaignStatus.SCHEDULED ||
							campaign.info.status === CampaignStatus.IN_PROGRESS ||
							campaign.info.status === CampaignStatus.PAUSED ? 'campaigns.approximateCost' :
								'campaigns.totalCost') | translate
						}}
					</span>
					<alaris-icon [alarisTooltip]="'campaigns.approximateCostHint' | translate"
								 name="icon-info"></alaris-icon>
				</label>
				<div>
					@if (campaign.info.status === CampaignStatus.SCHEDULED || campaign.info.status === CampaignStatus.IN_PROGRESS || campaign.info.status === CampaignStatus.PAUSED) {
						{{ campaign.statistics?.preCalculatedCost?.min | currency: bs.currencyCode }}
						- {{ campaign.statistics?.preCalculatedCost?.max }}
					} @else {
						{{ campaign.statistics?.cost | currency: bs.currencyCode }}
					}
				</div>
			</div>
			<div class="card">
				<label>{{ 'campaigns.totalRecipients' | translate }}</label>
				<div>{{ campaign.statistics?.total }}</div>
			</div>
		</div>
	</div>

	<div class="charts">
		@if (campaign.statistics) {
			<tui-axes [axisXLabels]="axisXLabels"
					  [axisYLabels]="axisYLabels"
					  [class.dark]="config.themeType === 'dark'"
					  [class.seen]="isSeenExist"
					  [horizontalLines]="5"
					  axisY="none"
					  class="chart">
				<tui-bar-chart [collapsed]="true"
							   [max]="barChartMax"
							   [size]="null"
							   [value]="value">
				</tui-bar-chart>
			</tui-axes>
		} @else {
			<tui-axes [horizontalLines]="5"
					  axisY="none"
					  class="chart stub-chart">
				<tui-bar-chart [max]="20000"
							   [size]="null"
							   [value]="stubValue">
				</tui-bar-chart>
			</tui-axes>
			<div class="stub-chart-note">{{ 'dashboard.noData' | translate }}</div>
		}
	</div>

	<!--Table for cascade campaigns: start-->
	@if (campaign.setup.channels.length > 1) {
		<span class="table-title">{{ 'campaigns.cascadeStep' | translate }}</span>
		@if (campaign.info.status !== CampaignStatus.SCHEDULED) {
			<table>
				<thead>
				<ng-container *ngTemplateOutlet="cascadeTableHead"></ng-container>
				</thead>
				<tbody>
					@for (channelStatistics of campaign.statistics?.campaignChannelStatistics; track channelStatistics; let i = $index; let first = $first) {
						<tr [class.top-border]="first">
							<td>
								<ng-container *ngTemplateOutlet="cascadeTableFirstColumn;
										   context:{trafficType : channelStatistics.trafficType, i}">
								</ng-container>
							</td>
							<td>{{ channelStatistics.sent }}</td>
							<td>{{ channelStatistics.delivered }}</td>
							<td>
								@if (channelStatistics.trafficType === TrafficType.VIBER) {
									{{ channelStatistics.seen }}
								} @else {
									<span class="tertiary">&mdash;</span>
								}
							</td>
							<td>{{ channelStatistics.undelivered }}</td>
							<td>{{ channelStatistics.failed }}</td>
						</tr>
					}
				</tbody>
			</table>
		} @else {
			<table>
				<thead>
				<ng-container *ngTemplateOutlet="cascadeTableHead"></ng-container>
				</thead>
				<tbody>
					@for (channelStatistics of campaign.statistics?.campaignChannelStatistics; track channelStatistics; let i = $index; let first = $first) {
						<tr [class.top-border]="first">
							<td>
								<ng-container *ngTemplateOutlet="cascadeTableFirstColumn;
										context:{trafficType : channelStatistics.trafficType, i}">
								</ng-container>
							</td>
						</tr>
					}
				</tbody>
			</table>
		}
	}

	<ng-template #cascadeTableFirstColumn let-i="i" let-trafficType="trafficType">
		<div class="step">
			<alaris-icon [name]="cu.icon(trafficType)" class="channel-icon"></alaris-icon>
			{{ ('gl.step' | translate) + (i + 1) }}
			&#183;
			{{ (cu.name(trafficType) | translate) }}
			@if (campaignUtils.prepareTTL(campaign, trafficType)) {
				<alaris-tag class="ttl" mode="gray-light">
					{{ 'campaigns.ttl' | translate : campaignUtils.prepareTTL(campaign, trafficType) }}
				</alaris-tag>
			}
		</div>
	</ng-template>

	<ng-template #cascadeTableHead>
		<tr>
			<th>{{ 'campaigns.stepCascade' | translate }}</th>
			<th>{{ 'enums.SENT' | translate }}</th>
			<th>{{ 'enums.DELIVERED' | translate }}</th>
			<th>{{ 'enums.SEEN' | translate }}</th>
			<th>{{ 'enums.UNDELIVERED' | translate }}</th>
			<th>{{ 'enums.FAILED' | translate }}</th>
		</tr>
	</ng-template>
	<!--Table for cascade campaigns: end-->
</div>

<div class="template">
	<div class="channels">
		@if (tabs.length > 1) {
			<alaris-tabs-button [bg]="'darken'"
								[formControl]="activeValue"
								[tabs]="tabs"
								data-testid="channelTypeTabs" size="sm">
			</alaris-tabs-button>
		}
	</div>
	<app-phone-message [buttonActionUrl]="campaignUtils.buttonActionUrl(campaign, activeValue.value)"
					   [buttonCaption]="campaignUtils.buttonCaption(campaign, activeValue.value)"
					   [file]="campaign.setup.file?.file ?? undefined"
					   [imageUrl]="campaignUtils.imageUrl(campaign, activeValue.value)"
					   [message]="campaignUtils.message(campaign, activeValue.value)"
					   [sender]="campaignUtils.sender(campaign, activeValue.value)"
					   [shortLink]="campaign.info.shortLink"
					   [trafficType]="activeValue.value">
	</app-phone-message>
</div>
