import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatePasswordRequest, ActivatePasswordResponse } from '@campaign-portal/namespace/entities/auth/specs';
import {
	AlarisApiService,
	AlarisLanguageService,
	ErrorNotifierConfig,
	ResetPasswordSubmit
} from '@campaign-portal/components-library';

@Injectable({
	providedIn: 'root'
})
export class ResetService {
	readonly loading$ = new BehaviorSubject(false);
	token = '';

	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });

	constructor(
		private api: AlarisApiService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get title(): string {
		return this.langService.translate('notifications.titles.auth');
	}

	activate({ newPassword, revokeSessions }: ResetPasswordSubmit): Observable<ActivatePasswordResponse> {
		const params: ActivatePasswordRequest = {
			Token: this.token,
			Password: newPassword,
			RevokeSessions: revokeSessions
		};
		return this.api.loader<ActivatePasswordResponse>(
			'Auth.ActivatePassword',
			params,
			this.loading$,
			this.errorNotifier
		);
	}

}
