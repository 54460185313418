import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ResetService } from './reset.service';

@Injectable()
export class ResetGuardService {

	constructor(
		private readonly resetPassword: ResetService,
		private readonly router: Router
	) {
	}

	canActivate(
		route: ActivatedRouteSnapshot
		// state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if ( route.queryParams.key ) {
			this.resetPassword.token = route.queryParams.key;
			return true;
		} else {
			this.router.navigate(['']).then();
		}
		return true;
	}

}

export const ResetGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
	return inject(ResetGuardService).canActivate(route);
};
