import { EntityField, InputType } from '@campaign-portal/namespace/common/entityField';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';

export const phone: EntityField = {
	id: 0,
	name: 'columns.destinationNumber',
	variable: 'phone',
	required: true,
	type: InputType.TEXT,
	filterType: FilterType.LIKE,
	readonly: true
};
export const message: EntityField = {
	id: 1,
	name: 'columns.message',
	variable: 'message',
	required: true,
	type: InputType.TEXT,
	filterType: FilterType.LIKE,
	readonly: true
};
export const imMessage: EntityField = {
	id: 2,
	name: 'templates.imMessage',
	variable: 'imMessage',
	required: true,
	type: InputType.TEXT,
	filterType: FilterType.LIKE,
	readonly: true
};
export const buttonActionUrl: EntityField = {
	id: 4,
	name: 'columns.buttonActionUrl',
	variable: 'buttonActionUrl',
	required: true,
	type: InputType.TEXT,
	filterType: FilterType.LIKE,
	readonly: true
};
export const imageUrl: EntityField = {
	id: 5,
	name: 'columns.imageUrl',
	variable: 'imageUrl',
	required: true,
	type: InputType.TEXT,
	filterType: FilterType.LIKE,
	readonly: true
};

export const buttonCaption: EntityField = {
	id: 6,
	name: 'columns.buttonCaption',
	variable: 'buttonCaption',
	required: true,
	type: InputType.TEXT,
	filterType: FilterType.LIKE,
	readonly: true
};

