import { Pipe, PipeTransform } from '@angular/core';
import { RepoService, RepoType } from './repo.service';
import { Channel } from '@campaign-portal/namespace/entities/channels/specs';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { IdObject } from '@campaign-portal/namespace/common/idObject';
import { ContactGroup } from '@campaign-portal/namespace/entities/contact-groups/specs';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { MessageTemplate } from '@campaign-portal/namespace/entities/templates/specs';
import { LinkDomain } from '@campaign-portal/namespace/entities/link-domains/specs';

@Pipe({
	name: 'channel',
	standalone: true
})
export class ChannelPipe implements PipeTransform {

	constructor(
		private readonly repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): Channel | null {
		return this.repo.transform(id, RepoType.CHANNELS);
	}
}

@Pipe({
	name: 'cGroup',
	standalone: true
})
export class ContactGroupPipe implements PipeTransform {

	constructor(
		private repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): ContactGroup | null {
		return this.repo.transform(id, RepoType.GROUPS) as (ContactGroup | null);
	}
}

@Pipe({
	name: 'cDeletedGroup',
	standalone: true
})
export class ContactDeletedGroupPipe implements PipeTransform {

	constructor(
		private repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): ContactGroup | null {
		return this.repo.transform(id, RepoType.DELETED_GROUPS) as (ContactGroup | null);
	}
}

@Pipe({
	name: 'sender',
	standalone: true
})
export class SenderPipe implements PipeTransform {

	constructor(
		private repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): SenderSubscription | null {
		return this.repo.transform(id, RepoType.SENDERS) as (SenderSubscription | null);
	}
}

@Pipe({
	name: 'message',
	standalone: true
})
export class MessageTemplatePipe implements PipeTransform {

	constructor(
		private repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): MessageTemplate | null {
		return this.repo.transform(id, RepoType.TEMPLATE) as (MessageTemplate | null);
	}
}

@Pipe({
	name: 'domain',
	standalone: true
})
export class LinkDomainPipe implements PipeTransform {

	constructor(
		private repo: RepoService
	) {
	}

	transform(id: Id<exist> | IdObject<exist>): LinkDomain | null {
		return this.repo.transform(id, RepoType.LINK_DOMAIN);
	}
}

