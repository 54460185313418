import { DestroyRef, inject, Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisDialogService,
	AlarisLanguageService,
	AlarisToasterService,
	ErrorNotifierConfig,
	ExtendableRefBookService
} from '@campaign-portal/components-library';

import { DeleteRequest, DeleteResponse, ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { creating, exist, Id } from '@campaign-portal/namespace/common/id';
import {
	ApiToken,
	ApiTokenCreateResponse,
	ApiTokenUpdateResponse
} from '@campaign-portal/namespace/entities/api/specs';
import { ApiKeyDialogComponent } from './api-keys/dialog/dialog.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
	providedIn: 'root'
})
export class ApiKeysService extends ExtendableRefBookService<ApiToken<exist>> implements AbstractCRUDService {

	read = this.load;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });
	private readonly destroyedRef = inject(DestroyRef);

	constructor(
		private readonly api: AlarisApiService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService,
		private readonly dialog: AlarisDialogService
	) {
		super();
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.api');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.api');
	}

	override load(params?: RPCRequestParams): Observable<ReadResponse<ApiToken<exist>[]>> {
		return this.api.loader<ReadResponse<ApiToken<exist>[]>>(
			'ApiToken.Read', params, this.loading$, this.errorNotifier
		)
			.pipe(
				map((resp) => {
					this.sortKeys(resp.Data);
					return super.process(resp);
				})
			);
	}

	create(apiKey: ApiToken<creating>): Observable<ApiTokenCreateResponse> {
		return this.api.loader<ApiTokenCreateResponse>(
			`ApiToken.Create`,
			{ Data: { Entities: [apiKey] } }, this.loading$
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	update(Ids: Id<exist>[]): Observable<ApiTokenUpdateResponse> {
		return this.api.loader<ApiTokenUpdateResponse>(
			'ApiToken.Update', { Data: { Ids } }, this.loading$, this.errorNotifier
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	delete(Ids: Id<exist>[]): Observable<DeleteResponse<ApiToken>> {
		const params: DeleteRequest<ApiToken<exist>> = { Data: { Ids } };
		const notify = (response: DeleteResponse<ApiToken<exist>>): void => {
			if ( response.Success ) {
				const message = this.langService.translate('notifications.actions.delete', {
					entity: this.entity,
					name: this.map.get(Ids[0])?.name ?? ''
				});
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<ApiToken<exist>>>(
			'ApiToken.Delete', params, this.loading$, this.errorNotifier, notify
		).pipe(tap(() => {
			this.refresh$.next();
		}));
	}

	openSpecificDialog(
		name: string,
		token: string,
		callback = (): void => {
		}
	): void {
		this.dialog.open(ApiKeyDialogComponent, {
			data: { name, token },
			autoFocus: false
		}).closed
			.pipe(takeUntilDestroyed(this.destroyedRef))
			.subscribe(() => {
				callback();
			});
	}

	private sortKeys(keys: ApiToken<exist>[]): void {
		const isExpired = (token: ApiToken): boolean => Boolean(token.expires && new Date() > new Date(token.expires));
		keys.sort((a, b) => {
			const first = isExpired(a) ? 1 : 0;
			const second = isExpired(b) ? 1 : 0;
			return first - second;
		});
	}
}
