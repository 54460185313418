import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input } from '@angular/core';
import { Campaign } from '@campaign-portal/namespace/entities/campaigns/specs';
import {
	AlarisFilesService,
	AlarisIconDirective,
	AlarisTagComponent,
	formatBytes
} from '@campaign-portal/components-library';
import { ContactGroupPipe } from '@helpers/repo/repo.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ContactGroupsService } from '../../../../contacts/services/contact-groups.service';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-recipients',
	templateUrl: './recipients.component.html',
	styleUrls: ['./recipients.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AlarisIconDirective,
		AlarisTagComponent,
		ContactGroupPipe,
		TranslateModule,
		AsyncPipe
	],
	standalone: true
})
export class RecipientsComponent {

	@Input() campaign!: Campaign;
	protected readonly formatBytes = formatBytes;
	private readonly destroyRef = inject(DestroyRef);

	constructor(
		readonly contactGroups: ContactGroupsService,
		private readonly fs: AlarisFilesService
	) {
	}

	export(fileId: string, fileName?: string): void {
		this.fs.export(fileId, fileName).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
	}
}
