import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import {
	AlarisButtonComponent,
	AlarisEditPanelService,
	AlarisFadeDirective,
	AlarisFileLoaderComponent,
	AlarisIconDirective,
	AlarisInputComponent,
	AlarisLoadingDirective,
	AlarisMultiOptions2Component,
	AlarisMultiSelect2Component,
	AlarisTextAreaComponent,
	CustomValidators
} from '@campaign-portal/components-library';

import { EnabledDisabledStatus } from '@campaign-portal/namespace/common/enums';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { Channel } from '@campaign-portal/namespace/entities/channels/specs';
import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';

import { ChannelsService } from '@helpers/services/channels.service';
import { CanDeactivateComponent } from '@helpers/shared/can-deactivate/component-deactivate';
import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';
import { SendersService } from '../../services/senders.service';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-sender-request',
	templateUrl: './sender-request.component.html',
	styleUrls: ['./sender-request.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AlarisLoadingDirective,
		AlarisIconDirective,
		TranslateModule,
		AlarisFadeDirective,
		AlarisInputComponent,
		AlarisMultiSelect2Component,
		AlarisMultiOptions2Component,
		AsyncPipe,
		AlarisTextAreaComponent,
		AlarisFileLoaderComponent,
		AlarisButtonComponent,
		ReactiveFormsModule
	],
	standalone: true
})
export class SenderRequestComponent extends CanDeactivateComponent implements OnInit {

	senderIdForm!: FormGroup;

	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	constructor(
		private readonly editPanel: AlarisEditPanelService,
		public readonly sendersService: SendersService,
		public readonly channelService: ChannelsService,
		private readonly guard: CanDeactivateGuardService
	) {
		super();
		this.addEditPanelGuard(editPanel, this.guard);
	}

	close(): void {
		this.editPanel.close();
	}

	ngOnInit(): void {
		this.senderIdForm = new FormGroup({
			name: new FormControl<string>('', [Validators.required]),
			channels: new FormControl<null | Channel[]>([], [CustomValidators.requiredArrayOrNull]),
			comment: new FormControl<string>(''),
			documents: new FormControl<FileInfo[]>([])
		});
	}

	save(): void {
		const value = this.senderIdForm.value;
		const senderIds: SenderSubscription | SenderSubscription[] = value.channels === null
			? this.channelService.list.map(this.mapChannels.bind(this))
			: value.channels.map(this.mapChannels.bind(this));

		this.allowedDeactivation.next(false);
		this.sendersService.requestSenderId(senderIds)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				this.allowedDeactivation.next(true);
				if ( result.Success ) {
					this.editPanel.close();
				}
			});
	}

	private mapChannels(channel: Channel): SenderSubscription {
		const sender: SenderSubscription = {
			id: null,
			...this.senderIdForm.value,
			name: this.senderIdForm.controls.name.value.trim(),
			trafficType: channel.channelType,
			enabled: EnabledDisabledStatus.DISABLED,
			subscribers: []
		};
		delete sender.channels;
		return sender;
	}
}
