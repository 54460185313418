import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';

@Component({
	selector: 'app-base-can-deactivate',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class BaseCanDeactivateComponent {
	// remove to prompt disappear when reload page
	@HostListener('window:beforeunload', ['$event'])
	unloadNotification($event: any): void {
		if ( !this.canDeactivate() ) {
			$event.returnValue = true;
		}
	}

	abstract canDeactivate(): boolean;
}
