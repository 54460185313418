<div class="title">{{ 'gl.progress' | translate }}</div>
<div class="grid">
	@for (status of statuses; track status) {
		@if (status.value) {
			<div class="row">
				<div [style.background]="getStatusColor(status.mode, alarisConfig.themeType)" class="dot"></div>
				<div>{{ status.name | translate }}</div>
				<div class="dots"></div>
				<span class="value">{{ status.value }}</span>
			</div>
		}
	}
	<div class="row total">
		<div>{{ 'gl.total' | translate }}</div>
		<div></div>
		<div></div>
		<strong>{{ campaign.statistics?.total ?? 0 }}</strong>
	</div>
</div>
