import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Campaign, CampaignStatus } from '@campaign-portal/namespace/entities/campaigns/specs';
import { AlarisIconDirective } from '@campaign-portal/components-library';

@Component({
	selector: 'app-campaign-list-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AlarisIconDirective
	],
	standalone: true
})
export class AvatarComponent {

	@Input() campaign!: Campaign;
	readonly CampaignStatus = CampaignStatus;

	constructor() {
	}

}
