import { EdrStatus } from '@campaign-portal/namespace/common/enums';
import { AlarisThemeType } from '@campaign-portal/components-library/lib/utils/providers/global/app.config.service';

export function getStatusColor(status: EdrStatus, theme: AlarisThemeType = 'light'): string {
	const isLight = theme === 'light';
	const map: { [key in EdrStatus]: string } = {
		[EdrStatus.DELIVERED]: isLight ? 'var(--as-Green-500)' : 'var(--as-Green-600)',
		[EdrStatus.SENT]: isLight ? 'var(--as-Blue-Light-400)' : 'var(--as-Blue-Light-500)',
		[EdrStatus.FAILED]: isLight ? 'var(--as-Red-450)' : 'var(--as-Red-Light-650)',
		[EdrStatus.SEEN]: isLight ? 'var(--as-Violet-400)' : 'var(--as-Violet-600)',
		[EdrStatus.UNDELIVERED]: isLight ? 'var(--as-Gray-300)' : 'var(--as-Gray-400)'
	};
	return map[status];
}
