import { RouterDataLinks } from '@campaign-portal/components-library';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { CanDeactivateGuard } from '@helpers/shared/can-deactivate/can-deactivate.guard';
import { CampaignsComponent } from '../../pages/campaigns/campaigns.component';

export const routerLinks: RouterDataLinks = {
	dashboard: {
		path: 'dashboard',
		loadComponent: () =>
			import('../../pages/dashboard/dashboard.component')
				.then(m => m.DashboardComponent),
		data: {
			permissions: [],
			title: 'dashboard.title',
			icon: 'icon-dashboard-square',
			animationState: 'DashboardComponent',
			overflow: true
		}
	},
	contacts: {
		path: 'contacts',
		loadComponent: () =>
			import('../../pages/contacts/contacts.component')
				.then(m => m.ContactsComponent),
		data: {
			permissions: [CP_PERMISSIONS.CONTACTS_R],
			title: 'contacts.title',
			icon: 'icon-contact',
			animationState: 'ContactsComponent'
		}
	},
	importContacts: {
		path: 'import-contacts',
		loadComponent: () =>
			import('../../pages/contacts/contacts-import/contacts-import.component')
				.then(m => m.ContactsImportComponent),
		canDeactivate: [
			CanDeactivateGuard
		],
		data: {
			permissions: [CP_PERMISSIONS.CONTACTS_R],
			title: 'contacts.import.title',
			icon: '',
			animationState: 'ContactsImportComponent',
			backRoute: true,
			hiddenMenu: true
		}
	},
	campaigns: {
		path: 'campaigns',
		data: {
			permissions: [
				CP_PERMISSIONS.CAMPAIGNS_R,
				CP_PERMISSIONS.CAMPAIGNS_E,
				CP_PERMISSIONS.MESSAGE_TEMPLATES_R,
				CP_PERMISSIONS.SENDERS_R
			],
			title: 'campaigns.title',
			icon: 'icon-campaign',
			animationState: 'CampaignsComponent',
			permissionComparison: 'some'
		},
		children: [
			{
				path: 'list',
				// loadComponent: () =>
				// 	import('../../pages/campaigns/campaigns.component')
				// 		.then(m => m.CampaignsComponent),
				component: CampaignsComponent,
				data: {
					permissions: [CP_PERMISSIONS.CAMPAIGNS_R],
					title: 'campaigns.campaignsList',
					icon: '',
					animationState: 'CampaignsComponent'
				}
			},
			{
				path: 'campaign-wizard',
				loadComponent: () =>
					import('../../pages/campaigns/campaign-wizard/campaign-wizard.component')
						.then(m => m.CampaignWizardComponent),
				data: {
					permissions: [CP_PERMISSIONS.CAMPAIGNS_E],
					title: 'campaigns.wizard',
					animationState: 'CampaignWizardComponent'
				},
				canDeactivate: [
					CanDeactivateGuard
				]
			},
			{
				path: 'templates',
				loadComponent: () =>
					import('../../pages/campaigns/message-templates/message-templates.component')
						.then(m => m.MessageTemplatesComponent),
				data: {
					permissions: [CP_PERMISSIONS.MESSAGE_TEMPLATES_R],
					title: 'templates.title',
					animationState: 'MessageTemplatesComponent'
				}
			},
			{
				path: 'senders',
				loadComponent: () =>
					import('../../pages/campaigns/senders/senders.component')
						.then(m => m.SendersComponent),
				data: {
					permissions: [CP_PERMISSIONS.SENDERS_R],
					title: 'senderIds.title',
					animationState: 'SendersComponent'
				}
			}
		]
	},
	statistics: {
		path: 'statistics',
		loadComponent: () =>
			import('../../pages/statistics/statistics.component')
				.then(m => m.StatisticsComponent),
		data: {
			permissions: [CP_PERMISSIONS.STATS_R],
			title: 'statistics.title',
			icon: 'icon-statistics',
			animationState: 'StatisticsComponent'
		}
	},
	purchase: {
		path: 'purchase',
		loadComponent: () =>
			import('../../pages/finance/purchase/purchase.component')
				.then(m => m.PurchaseComponent),
		data: {
			permissions: [CP_PERMISSIONS.SUBSCR_R],
			title: 'purchase.title',
			icon: 'icon-purchase-cp',
			animationState: 'PurchaseComponent',
			overflow: true
		}
	},
	urlShortener: {
		path: 'url-shortener',
		loadComponent: () =>
			import('../../pages/url-shortener/url-shortener.component')
				.then(m => m.UrlShortenerComponent),
		data: {
			permissions: [CP_PERMISSIONS.URL_SHORTENER_E],
			title: 'urlShortener.title',
			icon: 'icon-link',
			animationState: 'UrlShortenerComponent'
		}
	},
	finance: {
		path: 'finance',
		loadComponent: () =>
			import('../../pages/finance/invoices/invoices.component')
				.then(m => m.InvoicesComponent),
		data: {
			permissions: [CP_PERMISSIONS.TRANSACTIONS_R],
			title: 'purchase.finance',
			icon: 'icon-finance-cp',
			animationState: 'InvoicesComponent'
		}
	},
	accountSettings: {
		path: 'account-settings',
		loadComponent: () =>
			import('@campaign-portal/components-library')
				.then(m => m.AlarisAccountSettingsComponent),
		data: {
			permissions: [],
			title: 'accountSettings.title',
			icon: '',
			animationState: 'AccountSettingsComponent',
			hiddenMenu: true
		}
	},
	api: {
		path: 'connections',
		loadComponent: () =>
			import('../../pages/api/api.component')
				.then(m => m.ApiComponent),
		data: {
			permissions: [CP_PERMISSIONS.API_CONNECTION_E],
			title: 'api.title',
			icon: 'icon-api-connections',
			animationState: 'ApiComponent'
		}
	}
};
