import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

import {
	AlarisAuthService,
	AlarisBalanceComponent,
	AlarisContentLayoutService,
	AlarisMenuSidebarComponent,
	AlarisNavbarComponent,
	AlarisProfileComponent,
	AlarisRootContentComponent,
	LAYOUT_SERVICE_INJECTOR
} from '@campaign-portal/components-library';

import { routerLinks } from '@helpers/shared/router-links.const';
import { AuthGuardService } from '../../auth/auth.guard';
import { Subject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

function mainLayoutServiceFact(elementRef: ElementRef, router: Router): AlarisContentLayoutService {
	return new AlarisContentLayoutService(elementRef.nativeElement, router);
}

@Component({
	selector: 'app-main-layout',
	templateUrl: './main-layout.component.html',
	styleUrls: ['./main-layout.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: LAYOUT_SERVICE_INJECTOR, useFactory: mainLayoutServiceFact, deps: [ElementRef, Router] }],
	animations: [
		trigger('mainLayoutChanged', [
			transition('* <=> *', [
				style({ height: 0, opacity: 0 }),
				animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ height: '*', opacity: 1 }))
			])
		]),
		trigger(
			'inOutAnimation',
			[
				transition(
					':enter',
					[
						style({ width: 0, opacity: 0 }),
						animate('150ms ease-out',
							style({ width: 240, opacity: 1 }))
					]
				),
				transition(
					':leave',
					[
						style({ width: 240, opacity: 1 }),
						animate('150ms ease-in',
							style({ width: 0, opacity: 0 }))
					]
				)
			]
		)
	],
	imports: [
		RouterOutlet,
		AlarisProfileComponent,
		AlarisBalanceComponent,
		AlarisNavbarComponent,
		AlarisRootContentComponent,
		AlarisMenuSidebarComponent,
		AsyncPipe,
		TranslateModule
	],
	standalone: true
})
export class MainLayoutComponent implements OnDestroy {

	readonly menuList = routerLinks;

	private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		@Inject(LAYOUT_SERVICE_INJECTOR) public readonly layoutService: AlarisContentLayoutService,
		public readonly auth: AlarisAuthService,
		public readonly authGuard: AuthGuardService
	) {
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	prepareInterfaceRoute(outlet: RouterOutlet): boolean {
		return outlet &&
			outlet.activatedRouteData &&
			outlet.activatedRouteData.animationState;
	}
}
