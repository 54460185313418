import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, filter, merge, of, switchMap } from 'rxjs';

import { AlarisDialogService, AlarisEditPanelService } from '@campaign-portal/components-library';

import { BaseCanDeactivateComponent } from '@helpers/shared/can-deactivate/base-deactivate';
import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';

@Component({
	selector: 'app-component-can-deactivate',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export abstract class CanDeactivateComponent extends BaseCanDeactivateComponent {
	abstract allowedDeactivation: BehaviorSubject<boolean>;
	readonly destroyRef = inject(DestroyRef);

	protected constructor() {
		super();
	}

	canDeactivate(): boolean {
		return this.allowedDeactivation.getValue();
	}

	addEditPanelGuard(editPanel: AlarisEditPanelService, guard: CanDeactivateGuardService): void {
		editPanel.canBeClosed$ = this.allowedDeactivation;
		merge(
			editPanel.closingComponent$,
			editPanel.escKeyInComponent$,
			editPanel.backdropClickComponent$
		)
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				switchMap(() => {
					return editPanel.canBeClosed
						? of(true)
						: guard.lostDataDialog();
				})
			)
			.subscribe(result => {
				this.allowedDeactivation.next(result);
				editPanel.close();
			});
	}

	addDialogGuard(dialog: AlarisDialogService, dialogRef: DialogRef, guard: CanDeactivateGuardService): void {
		let manualClosingResult: unknown;
		dialog.canBeClosed$ = this.allowedDeactivation;
		merge(
			dialogRef.backdropClick,
			dialogRef.keydownEvents.pipe(filter((value: KeyboardEvent) => value.code === 'Escape')),
			dialog.manualClosing$.pipe(filter((closingDialog => {
				if ( closingDialog.ref === dialogRef ) {
					manualClosingResult = closingDialog.result;
					return true;
				}
				return false;
			})))
		)
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				switchMap(() => {
					return dialog.canBeClosed
						? of(true)
						: guard.lostDataDialog();
				}))
			.subscribe((canDeactivate) => {
				this.allowedDeactivation.next(canDeactivate);
				if ( canDeactivate ) {
					dialog.defaultClose.call(dialogRef, manualClosingResult);
					manualClosingResult = undefined;
				}
			});
	}
}
