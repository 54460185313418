import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { BehaviorSubject, delay, filter, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlarisDialogComponent } from '@campaign-portal/components-library';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

export interface ApiKeyDialogData {
	name: string,
	token: string;
}

@Component({
	selector: 'app-api-key-dialog',
	styles: `
		.header {
			margin: 0;
			overflow-wrap: anywhere;
		}

		.content {
			display: flex;
			flex-direction: column;
			gap: 1rem;
		}

		.token {
			cursor: text;
			background: var(--as-BgSecondary);
			width: 100%;
			height: 100%;
			border-radius: var(--as-border-radius);
			display: flex;
			padding: 1rem;
			color: var(--as-TextPrimary);
			overflow-wrap: anywhere;
		}

		.note {
			color: var(--as-TextSecondary);

			strong {
				color: var(--as-TextPrimary);
			}
		}
	`,
	templateUrl: './dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AlarisDialogComponent,
		AsyncPipe,
		TranslateModule
	],
	standalone: true
})
export class ApiKeyDialogComponent {

	token: string;
	name: string;

	public readonly copied$ = new BehaviorSubject<boolean>(false);

	constructor(
		@Inject(DIALOG_DATA) public readonly data: ApiKeyDialogData,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly clipboard: Clipboard
	) {
		this.token = data.token;
		this.name = data.name;
		this.copied$.pipe(
			takeUntilDestroyed(),
			filter(copied => copied),
			delay(3000),
			tap(() => {
				this.copied$.next(false);
			})
		).subscribe();
	}

	close(): void {
		this.dialogRef.close();
	}

	copy(): void {
		this.clipboard.copy(this.token);
		this.copied$.next(true);
	}
}
