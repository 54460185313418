export enum CP_PERMISSIONS {
	API_CONNECTION_E = 'api_connection_edit',
	CONTACTS_R = 'contacts_read',
	CONTACTS_E = 'contacts_edit',
	CAMPAIGNS_R = 'campaigns_read',
	CAMPAIGNS_E = 'campaigns_edit',
	MESSAGE_TEMPLATES_R = 'message_templates_read',
	MESSAGE_TEMPLATES_E = 'message_templates_edit',
	SENDERS_R = 'sender_ids_read',
	SENDERS_E = 'sender_ids_edit',
	STATS_R = 'statistics_read',
	SUBSCR_R = 'subscriptions_read',
	SUBSCR_E = 'subscriptions_edit',
	TRANSACTIONS_R = 'transaction_history_read',
	URL_SHORTENER_E = 'url_shortener_edit'
}
