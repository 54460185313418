import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';


import {
	AbstractCRUDService,
	AlarisApiService,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisFilesService,
	AlarisLanguageService,
	AlarisToasterService,
	EditPanelWidth,
	ErrorNotifierConfig
} from '@campaign-portal/components-library';

import {
	CreateResponse,
	DeleteRequest,
	DeleteResponse,
	ReadResponse
} from '@campaign-portal/namespace/common/implementations';
import {
	ApproximateRequest,
	ApproximateResponse,
	Campaign,
	CampaignActionRequest,
	CampaignActionResponse
} from '@campaign-portal/namespace/entities/campaigns/specs';
import { Captcha, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { creating, exist } from '@campaign-portal/namespace/common/id';
import { FilesExportResponse } from '@campaign-portal/namespace/entities/files/specs';

import { CampaignDetailsComponent } from '../campaign-list/campaign-details/campaign-details.component';
import { CampaignsDialogComponent, CampaignsDialogType } from '../shared/dialog/campaigns-dialog.component';


export enum CampaignAction {
	RESUME = 'Resume',
	RESEND_FAILED = 'ResendFailedMessages',
	PAUSE = 'Pause',
	CANCEL = 'Cancel',
}

@Injectable({
	providedIn: 'root'
})
export class CampaignsService implements AbstractCRUDService {
	readonly create = this.update;
	private readonly errorNotifier = (): ErrorNotifierConfig => ({ title: this.title });
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly forceLoad$ = new Subject<void>();

	constructor(
		private readonly api: AlarisApiService,
		private readonly fs: AlarisFilesService,
		private readonly editPanel: AlarisEditPanelService,
		private readonly dialog: AlarisDialogService,
		private readonly alarisToaster: AlarisToasterService,
		private readonly langService: AlarisLanguageService
	) {
	}

	get entity(): string {
		return this.langService.translate('notifications.entities.campaign');
	}

	get title(): string {
		return this.langService.translate('notifications.titles.campaign');
	}

	read(params?: RPCRequestParams): Observable<ReadResponse<Campaign<exist>[]>> {
		return this.api.loader<ReadResponse<Campaign<exist>[]>>(
			'Campaigns.Read', params, this.loading$, this.errorNotifier
		);
	}

	update(campaign: Campaign, captcha?: Captcha): Observable<CreateResponse<Campaign>> {
		const params = { Data: { Entities: [campaign] } };
		if ( campaign.id === null ) {
			Object.assign(params, { Captcha: captcha });
		}
		return this.api.loader<CreateResponse<Campaign>>(
			`Campaigns.${campaign.id ? 'Update' : 'Create'}`,
			params,
			this.loading$,
			this.errorNotifier
		).pipe(map((resp) => {
			this.forceLoad$.next();
			const message = this.langService.translate(
				campaign.id ? 'notifications.actions.update' : 'notifications.actions.create', {
					entity: this.entity,
					name: campaign.info.name ?? ''
				});
			if ( resp.Success ) {
				this.alarisToaster.success(message, this.title);
			}
			return resp;
		}));
	}

	delete(campaign: Campaign<exist>): Observable<DeleteResponse<Campaign>> {
		const params: DeleteRequest<Campaign<exist>> = { Data: { Ids: [campaign.id] } };
		const notify = (response: DeleteResponse<Campaign>): void => {
			const message = this.langService.translate('notifications.actions.delete', { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<DeleteResponse<Campaign>>(
			'Campaigns.Delete', params, this.loading$, this.errorNotifier, notify
		)
			.pipe(map((resp) => {
				this.forceLoad$.next();
				return resp;
			}));
	}

	approximate(campaign: Campaign<creating>): Observable<ApproximateResponse> {
		const params: ApproximateRequest = {
			Data: {
				Entities: [campaign]
			}
		};
		return this.api.loader<ApproximateResponse>('Campaigns.Approximate', params, this.loading$, this.errorNotifier)
			.pipe(map((resp) => {
				// this.forceLoad.next();
				return resp;
			}));
	}

	action(action: CampaignAction, campaign: Campaign<exist>): Observable<CampaignActionResponse> {
		const params: CampaignActionRequest = { Data: { Ids: [campaign.id] } };
		const notify = (response: CampaignActionResponse): void => {
			const message = this.langService.translate(`notifications.actions.${action.toLowerCase()}`, { entity: this.entity });
			if ( response.Success ) {
				this.alarisToaster.success(message, this.title);
			}
		};
		return this.api.loader<CampaignActionResponse>(
			`Campaigns.${action}`, params, this.loading$, this.errorNotifier, notify
		)
			.pipe(map((resp) => {
				this.forceLoad$.next();
				return resp;
			}));
	}

	export(fileId: string, fileName?: string): Observable<FilesExportResponse> {
		return this.fs.export(fileId, fileName);
	}

	openDetailsPanel(campaign: Campaign): void {
		this.editPanel.open(CampaignDetailsComponent, EditPanelWidth.XL, {
			campaign
		});
	}

	openDialog(
		type: CampaignsDialogType,
		item: Campaign
	): Observable<boolean | undefined> {
		return this.dialog.open<boolean>(CampaignsDialogComponent, {
			data: { campaign: item, type },
			autoFocus: false
		}).closed
			.pipe(
				map((result) => {
					if ( result ) {
						// this.forceLoad.next();
					}
					return result;
				})
			);
	}
}
