import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { BehaviorSubject, finalize, Observable, of, Subject } from 'rxjs';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { exist } from '@campaign-portal/namespace/common/id';
import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { Campaign, CampaignStatus } from '@campaign-portal/namespace/entities/campaigns/specs';
import {
	AlarisButtonComponent,
	AlarisCaptchaComponent,
	AlarisCardComponent,
	AlarisDialogService,
	AlarisIconDirective,
	AlarisLoadingDirective,
	AlarisTextAreaComponent
} from '@campaign-portal/components-library';
import { CampaignsService } from '../../services/campaigns.service';
import { CanDeactivateComponent } from '@helpers/shared/can-deactivate/component-deactivate';
import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';

export interface CampaignsDialogData {
	type: CampaignsDialogType;
	campaign: Campaign<exist>;
	total?: number;
}

export type CampaignsDialogType = 'Delete' | 'Captcha';

@Component({
	selector: 'app-campaigns-dialog',
	templateUrl: './campaigns-dialog.component.html',
	styleUrls: ['./shared-dialog.component.scss'],
	providers: [DatePipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AlarisCardComponent,
		AlarisIconDirective,
		TranslateModule,
		DatePipe,
		AlarisTextAreaComponent,
		AlarisCaptchaComponent,
		AlarisButtonComponent,
		AlarisLoadingDirective
	],
	standalone: true
})
export class CampaignsDialogComponent extends CanDeactivateComponent implements OnDestroy {

	readonly campaignStatus = CampaignStatus;
	campaign!: Campaign<exist>;
	readonly captcha = new FormGroup({
		answer: new FormControl('', { nonNullable: true, validators: Validators.required }),
		key: new FormControl('', { nonNullable: true, validators: Validators.required })
	});
	description: FormControl;
	total = 0;

	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly updateCaptcha$ = new Subject<void>();
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	constructor(
		@Inject(DIALOG_DATA) public readonly data: CampaignsDialogData,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly campaignsService: CampaignsService,
		private readonly translate: TranslateService,
		private readonly datePipe: DatePipe,
		private readonly cd: ChangeDetectorRef,
		private readonly dialog: AlarisDialogService,
		private readonly guard: CanDeactivateGuardService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.campaign = data.campaign;
		this.description = new FormControl(this.campaign.info?.description || '');
		this.total = data?.total || 0;
	}

	get disabled(): boolean {
		return this.loading$.getValue() || this.data.type === 'Captcha' ? this.captcha.invalid : false;
	}

	ngOnDestroy(): void {
		this.loading$.complete();
		this.updateCaptcha$.complete();
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'Delete':
				caller = this.campaignsService.delete(this.campaign);
				break;
			case 'Captcha':
				this.campaign.info.description = this.description.value ?? '';
				this.campaign.info.name = this.campaign.info.name
					? this.campaign.info.name
					: this.translate.instant('campaigns.campaign')
					+ ' '
					+ this.datePipe.transform(this.campaign.info.scheduled, 'd MMM y, HH:mm');
				caller = this.campaignsService
					.create(this.campaign, this.captcha.getRawValue())
					.pipe(finalize(() => {
						this.updateCaptcha$.next();
					}));
				break;
			default:
				break;
		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
			(resp) => {
				this.allowedDeactivation.next(true);
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}
}

