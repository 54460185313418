import { inject, Injectable } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AlarisAuthService } from '@campaign-portal/components-library';

@Injectable()
export class DropAuthGuardService {

	constructor(
		private readonly authService: AlarisAuthService
	) {
	}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this.authService.resetAuth();
		return true;
	}

}

export const DropAuthGuard: CanActivateFn =
	(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
		return inject(DropAuthGuardService).canActivate();
	};
