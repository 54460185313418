import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { FileInfo } from '@campaign-portal/namespace/common/fileInfo';
import { exist, Id } from '@campaign-portal/namespace/common/id';
import { DecisionMode, MessagePurpose, MessageSplitMode, TrafficType } from '@campaign-portal/namespace/common/enums';
import { Campaign, CampaignStatus } from '@campaign-portal/namespace/entities/campaigns/specs';
import { FileParsedColumn } from '@campaign-portal/namespace/entities/files/specs';

import { CPCustomValidators } from '@helpers/validators/custom-validators';
import {
	CampaignChannelSettingsControls,
	CampaignControls,
	CampaignInfoControls,
	CampaignMessageControls,
	CampaignOmniSettingsControls,
	CampaignSettingsControls,
	CampaignSetupControls,
	CampaignViberSettingsControls
} from '../shared/types/form-types';
import { ContactGroupsService } from '../../contacts/services/contact-groups.service';
import { MessageTemplatesService } from '../services/message-templates.service';
import { BehaviorSubject } from 'rxjs';
import { ShortLinkEntry, UrlShortenerUtilsService } from '../../url-shortener/url-shortener-utils.service';
import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { AlarisProfileService } from '@campaign-portal/components-library';

export const MAX_PHONE_NUMBER_AMOUNT = 1000;
export const CONTACTS_SPLIT_PATTERN = /[\s\n;,]+\s*/gm;

export type ShortLinkChannelsMap = Map<TrafficType, ShortLinkEntry | undefined>;

@Injectable({
	providedIn: 'root'
})
export class CampaignWizardService {

	totalRecipients = 0;
	forbiddenFlashWarning = false;

	readonly shortLinkChannelsMap$ = new BehaviorSubject<ShortLinkChannelsMap>(new Map());

	constructor(
		private readonly contactGroupsService: ContactGroupsService,
		private readonly templatesService: MessageTemplatesService,
		private readonly shortenerUtils: UrlShortenerUtilsService,
		private readonly profile: AlarisProfileService,
		private readonly translate: TranslateService,
		private readonly router: Router
	) {
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	_form!: FormGroup<CampaignControls>;

	get form(): FormGroup<CampaignControls> {
		return this._form;
	}

	set form(form: FormGroup<CampaignControls>) {
		this._form = form;
	}

	get infoControls(): CampaignInfoControls {
		return this._form.controls.info.controls;
	}

	get setupControls(): CampaignSetupControls {
		return this._form.controls.setup.controls;
	}

	get settingsControls(): CampaignSettingsControls {
		return this._form.controls.settings.controls;
	}

	get contactsControls(): FormGroup<{
		file: FormControl<FileInfo | undefined>;
		plain: FormGroup<{
			text: FormControl<string>;
			code: FormControl<number | null>;
		}>;
		groups: FormControl<Id<exist>[] | undefined>;
	}> {
		return this._form.controls.setup.controls.native.controls.contacts;
	}

	get channelsArray(): FormGroup<CampaignChannelSettingsControls>[] {
		return this._form.controls.setup.controls.channels.controls;
	}

	get contactsFile(): FormControl<FileInfo | undefined> {
		return this._form.controls.setup.controls.native.controls.contacts.controls.file;
	}

	get contactsPlain(): FormGroup<{ text: FormControl<string>; code: FormControl<number | null> }> {
		return this._form.controls.setup.controls.native.controls.contacts.controls.plain;
	}

	get contactGroups(): FormControl<Id<exist>[] | undefined> {
		return this._form.controls.setup.controls.native.controls.contacts.controls.groups;
	}

	findChannel(trafficType: TrafficType): FormGroup<CampaignChannelSettingsControls> | undefined {
		return this._form.controls.setup.controls.channels.controls.find((control) => {
			return control.controls.trafficType.value === trafficType;
		});
	}


	create(type: 'file' | 'native' = 'native', campaign?: Campaign): FormGroup<CampaignControls> {
		this._form = new FormGroup<CampaignControls>({
			id: new FormControl<Id>(null),
			info: new FormGroup({
				id: new FormControl<Id>(null),
				name: new FormControl<string>(
					campaign?.info.name || '',
					{ nonNullable: true }
				),
				description: new FormControl<string>(
					campaign?.info.description || '',
					{ nonNullable: true }
				),
				photo: new FormControl<string>('', { nonNullable: true }),
				scheduled: new FormControl<string>(
					new Date().toISOString(),
					{ nonNullable: true, validators: [Validators.required] }
				),
				status: new FormControl<CampaignStatus>(CampaignStatus.SCHEDULED, { nonNullable: true })
			}),
			setup: new FormGroup({
				native: new FormGroup({
					contacts: new FormGroup(
						{
							file: new FormControl<FileInfo | undefined>(
								{
									value: campaign?.setup.native?.contacts.file || undefined,
									disabled: !campaign?.setup.native?.contacts.file
								},
								{ nonNullable: true }
							),
							plain: new FormGroup({
								text: new FormControl<string>(
									{
										value: campaign?.setup.native?.contacts.plain?.text || '',
										disabled: !campaign?.setup.native?.contacts.plain?.text
									},
									{
										nonNullable: true,
										validators: [
											CPCustomValidators.recipientsLimitAndPattern(
												MAX_PHONE_NUMBER_AMOUNT,
												CONTACTS_SPLIT_PATTERN
											)
										]
									}
								),
								code: new FormControl<number | null>(
									{
										value: campaign?.setup.native?.contacts.plain?.code || null,
										disabled: !campaign?.setup.native?.contacts.plain?.code
									}
								)
							}),
							groups: new FormControl<Id<exist>[] | undefined>(
								{
									value: campaign?.setup.native?.contacts.groups || [],
									disabled: !campaign?.setup.native?.contacts.groups
										|| campaign?.setup.native?.contacts.groups.length === 0
								},
								{ nonNullable: true }
							)
						},
						[Validators.required]
					)
				}),
				file: new FormGroup({
					file: new FormControl<FileInfo | null>(null, { validators: Validators.required }),
					columns: new FormControl<FileParsedColumn[]>([], { nonNullable: true })
					// skipFirstRow: new FormControl<boolean>(false, { nonNullable: true })
				}),
				channels: new FormArray<FormGroup<CampaignChannelSettingsControls>>(
					campaign
						? campaign.setup.channels.reduce(
							(result: FormGroup<CampaignChannelSettingsControls>[], chSetup) => {
								const form = new FormGroup<CampaignChannelSettingsControls>({
									sender: new FormControl<Id<exist> | null>(chSetup.sender),
									trafficType: new FormControl<TrafficType>(
										chSetup.trafficType, { nonNullable: true }
									),
									message: chSetup.message
										? new FormGroup<CampaignMessageControls>({
											text: new FormControl<string | undefined>(
												chSetup.message.text,
												{ nonNullable: true }
											),
											templateID: new FormControl<Id<exist> | null>(
												chSetup.message.templateID || null
											)
										})
										: undefined
								});
								if ( chSetup.omni ) {
									form.addControl('omni',
										new FormGroup<CampaignOmniSettingsControls>({
											fallbackType: new FormControl<DecisionMode>(
												chSetup.omni?.fallbackType || DecisionMode.BY_SUBMITTED,
												{ nonNullable: true }
											),
											ttl: new FormControl<number>(
												chSetup.omni?.ttl || 30,
												{ nonNullable: true }
											),
											viberSettings: chSetup.omni?.viberSettings
												? new FormGroup<CampaignViberSettingsControls>({
													buttonActionUrl: new FormControl<string>(
														chSetup.omni.viberSettings.buttonActionUrl || '',
														{ nonNullable: true }
													),
													imageUrl: new FormControl<string>(
														chSetup.omni.viberSettings.imageUrl || '',
														{ nonNullable: true }
													),
													buttonCaption: new FormControl<string>(
														chSetup.omni.viberSettings.buttonCaption || '',
														{ nonNullable: true }
													),
													messagePurpose: new FormControl<MessagePurpose>(
														chSetup.omni.viberSettings.messagePurpose
														|| (
															chSetup.omni.viberSettings.buttonActionUrl
															|| chSetup.omni.viberSettings.imageUrl
															|| chSetup.omni.viberSettings.buttonCaption
																? MessagePurpose.PROMOTION : MessagePurpose.TRANSACTION
														),
														{ nonNullable: true }
													)
												})
												: undefined
										}));
								}
								result.push(form);
								return result;
							},
							[])
						: [],
					[Validators.required]
				)
			}),
			settings: new FormGroup({
				failureAlertThreshold: new FormControl<number>(
					{
						value: campaign?.settings.failureAlertThreshold || 100,
						disabled: !campaign?.settings.failureAlertThreshold
					},
					{ nonNullable: true }
				),
				emailReportAddresses: new FormControl<string>(
					{
						value: campaign?.settings.emailReportAddresses || '',
						disabled: !campaign?.settings.emailReportAddresses
					},
					{ nonNullable: true, validators: [Validators.required] } // todo add validators
				),
				messageSplitMode: new FormControl<MessageSplitMode>(
					campaign?.settings.messageSplitMode || MessageSplitMode.SPLIT,
					{ nonNullable: true }
				),
				isFlashed: new FormControl<boolean>(
					campaign?.settings.isFlashed || false,
					{ nonNullable: true }
				)
			})
		});
		if ( type === 'file' ) {
			this._form.controls.setup.controls.native.disable();
		} else {
			this._form.controls.setup.controls.file.disable();
		}
		return this._form;
	}

	reset(): void {
		this._form = this.create();
		this.totalRecipients = 0;
		this.forbiddenFlashWarning = false;
		this.shortLinkChannelsMap$.next(new Map());
	}

	prepare(type: 'file' | 'native' = 'native'): Campaign<null> {
		const _campaign: Campaign<null> = {
			id: null,
			info: {
				id: null,
				scheduled: '',
				status: CampaignStatus.SCHEDULED
			},
			setup: {
				channels: []
			},
			settings: {}
		};

		let campaign: Campaign<null>;

		if ( type === 'file' ) {
			Object.assign(_campaign.setup, {
				file: {
					// file: FileInfo;
					// columns: FileParsedColumn[];
					// skipFirstRow: boolean;
				}
			});
			campaign = Object.assign(_campaign, structuredClone(this.form.value));
		} else {
			Object.assign(_campaign.setup, {
				native: {
					contacts: []
				}
			});
			campaign = Object.assign(_campaign, structuredClone(this.form.value));
			if ( this.setupControls.native.controls.contacts.value.groups === null ) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				campaign.setup.native!.contacts.groups = this.contactGroupsService.list.map(cg => cg.id);
			}
			if ( this.setupControls.native.controls.contacts.value.plain ) {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				campaign.setup.native!.contacts.plain = {
					code: this.contactsPlain.controls.code.value,
					text: this.contactsPlain.controls.text.value
						.replace(CONTACTS_SPLIT_PATTERN, ',')
				};
			}
			if ( campaign.setup.channels ) {
				campaign.setup.channels = campaign.setup.channels.sort((ch1, _ch2) => {
					if ( ch1.trafficType === TrafficType.SMS ) {
						return 1;
					}
					return -1;
				});
			}
		}

		return campaign;
	}

	repeat(campaign: Campaign): void {
		if ( campaign.id ) {
			campaign.info.name = this.translate.instant('actions.repeat') + ' ' + campaign.info.name;
		}
		if ( campaign.settings.isFlashed && !this.profile.user.details.isFlashed ) {
			this.forbiddenFlashWarning = true;
			campaign.settings.isFlashed = false;
		}
		this.totalRecipients = campaign.statistics?.total || 0;
		this.create('native', campaign);
		this.checkShortLinks();
		this.router.navigate(['campaigns', 'campaign-wizard']);
	}

	checkShortLinks(): void {
		if ( !this.profile.allowed([CP_PERMISSIONS.URL_SHORTENER_E]) ) {
			return;
		}

		const shortLinkChannelsMap: ShortLinkChannelsMap = new Map();
		this.channelsArray.forEach(ch => {
			const channel = ch.getRawValue();
			if ( !channel.message ) {
				return;
			}
			const message = channel.message.text
				? channel.message.text
				: this.templatesService.map.get(channel.message.templateID)?.messageTemplate ?? '';

			shortLinkChannelsMap.set(
				channel.trafficType,
				this.shortenerUtils.extractShortLink(message)
			);
		});

		this.shortLinkChannelsMap$.next(shortLinkChannelsMap);
	}
}
