import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { Campaign } from '@campaign-portal/namespace/entities/campaigns/specs';
import { CampaignTemplateTooltipComponent } from './campaign-template-tooltip/tooltip.component';
import { Subject, takeUntil } from 'rxjs';
import {
	AlarisComplexTooltipDirective,
	AlarisFilesService,
	AlarisIconDirective,
	AlarisTooltipDirective,
	formatBytes
} from '@campaign-portal/components-library';
import { NgTemplateOutlet } from '@angular/common';
import { SenderPipe } from '@helpers/repo/repo.pipe';

@Component({
	selector: 'app-template',
	templateUrl: './template.component.html',
	styleUrls: ['./template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgTemplateOutlet,
		AlarisIconDirective,
		SenderPipe,
		AlarisComplexTooltipDirective,
		AlarisTooltipDirective
	],
	standalone: true
})
export class TemplateComponent implements OnDestroy {

	@Input() campaign!: Campaign;

	readonly CampaignTemplateTooltipComponent = CampaignTemplateTooltipComponent;
	protected readonly formatBytes = formatBytes;
	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly fs: AlarisFilesService
	) {
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	export(fileId: string, fileName?: string): void {
		this.fs.export(fileId, fileName).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
	}
}
