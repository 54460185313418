<div [alarisLoading]="sendersService.loading$">
	<div class="edit-panel-header">
		<alaris-icon name="icon-sender"></alaris-icon>
		{{ 'senderIds.requestSenderId' | translate }}
	</div>
	<div alarisFade="vertical" class="edit-panel-content">
		<div [formGroup]="senderIdForm" class="form-group">
			<alaris-input [label]="'senderIds.senderId' | translate"
						  [placeholder]="'senderIds.senderId' | translate"
						  data-testid="nameInput" formControlName="name">
			</alaris-input>
			<alaris-multi-select2 [label]="'senderIds.channels' | translate"
								  [optionsCount]="(channelService.list$ | async)?.length || 0"
								  [placeholder]="'senderIds.channels' | translate"
								  data-testid="channelsSelect" formControlName="channels"
								  selectedLabel="name">
				@for (opt of channelService.list$ | async; track $index) {
					<alaris-multi-options2 [attr.data-testid]="'channelsOptions/' + opt.name"
										   [value]="opt">
						{{ opt.name }}
					</alaris-multi-options2>
				}
			</alaris-multi-select2>
			<alaris-text-area data-testid="commentTextarea" formControlName="comment">
				{{ 'gl.comment' | translate }}
			</alaris-text-area>
			<alaris-file-loader (fileListChanges)="senderIdForm.get('documents')?.setValue($event)"
								[exportInternally]="true"
								data-testid="documentFile">
				{{ 'gl.documents' | translate }}
			</alaris-file-loader>
		</div>
	</div>
	<div class="edit-panel-footer">
		<button (click)="save()"
				[disabled]="senderIdForm.invalid || !!(sendersService.loading$ | async)"
				alaris-button bType="primary" data-testid="createBtn" size="lg"
				type="button">
			<alaris-icon class="in-primary" name="icon-success"></alaris-icon>
			{{ 'actions.create' | translate }}
		</button>
		<button (click)="close()" alaris-button bType="outline" data-testid="closeBtn" size="lg"
				type="button">{{ 'actions.cancel' | translate }}
		</button>
	</div>
</div>
