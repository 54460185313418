import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { RPCResult } from '@campaign-portal/namespace/common/rpc.response';
import { exist } from '@campaign-portal/namespace/common/id';
import { Package, Plan } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { SubscriptionType } from '@campaign-portal/namespace/common/enums';

import {
	AlarisAlertComponent,
	AlarisBalanceDialogComponent,
	AlarisBalanceService,
	AlarisDialogComponent,
	AlarisDialogService,
	AlarisIconDirective,
	AlarisLinkComponent,
	AlarisNoValueCheckPipe,
	AlarisTagComponent,
	AlarisTextAreaComponent,
	AlarisTooltipDirective,
	ChannelUtilsService,
	TimeAgoPipe
} from '@campaign-portal/components-library';

import { CanDeactivateComponent } from '@helpers/shared/can-deactivate/component-deactivate';
import { CanDeactivateGuardService } from '@helpers/shared/can-deactivate/can-deactivate.guard';
import { PurchaseService } from '../purchase.service';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe, NgTemplateOutlet } from '@angular/common';
import { PackageCountryNetworkListComponent } from '../package-details/package-country-network-list/package-country-network-list.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface PurchaseDialogData {
	type: PurchaseDialogType;
	package?: Package<exist>;
	plan?: Plan<exist>;
}

export type PurchaseDialogType = 'PackageRequest' | 'PlanRequest' | 'SelectPlan' | 'ShopNow';
export type PurchaseHintDialogType = 'AllCountries';

@Component({
	selector: 'app-dialogs',
	templateUrl: './dialogs.component.html',
	styleUrls: ['./dialogs.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AlarisDialogComponent,
		TranslateModule,
		AlarisTextAreaComponent,
		NgTemplateOutlet,
		AlarisTagComponent,
		AlarisAlertComponent,
		AlarisIconDirective,
		CurrencyPipe,
		AlarisLinkComponent,
		PackageCountryNetworkListComponent,
		AlarisTooltipDirective,
		AlarisNoValueCheckPipe,
		TimeAgoPipe
	],
	standalone: true
})
export class PurchaseDialogsComponent extends CanDeactivateComponent implements OnDestroy {
	readonly package!: Package<exist>;
	readonly plan!: Plan<exist>;
	readonly comment = new FormControl('', { nonNullable: true });
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly allowedDeactivation = new BehaviorSubject<boolean>(true);

	constructor(
		@Inject(DIALOG_DATA) public readonly data: PurchaseDialogData,
		public readonly cu: ChannelUtilsService,
		public readonly bs: AlarisBalanceService,
		public readonly purchaseService: PurchaseService,
		private readonly dialogRef: DialogRef<unknown>,
		private readonly cd: ChangeDetectorRef,
		private readonly guard: CanDeactivateGuardService,
		private readonly dialog: AlarisDialogService
	) {
		super();
		this.addDialogGuard(this.dialog, dialogRef, this.guard);
		this.package = data.package as Package<exist>;
		this.plan = data.plan as Plan<exist>;
	}

	get positiveBalance(): boolean {
		return this.package
			? this.bs.credit === null || this.bs.balance + this.bs.credit >= this.package.packSettings.packPrice
			: true;
	}

	get confirmButtonText(): string {
		switch (this.data.type) {
			case 'ShopNow':
				return 'purchase.buyNow';
			default:
				return 'actions.confirm';
		}
	}

	get disabled(): boolean {
		let comment = false;
		switch (this.data.type) {
			case 'PackageRequest':
			case 'PlanRequest':
				comment = this.comment.value.length > 0;
				break;
			case 'SelectPlan':
			case 'ShopNow':
				comment = this.positiveBalance;
				break;
			default:
				break;
		}
		return !this.loading$.getValue() && !comment;
	}

	seeAllCountries(): void {
		this.purchaseService.openSpecificHintDialog('AllCountries', this.plan || this.package);
	}

	close(): void {
		this.dialogRef.close(false);
	}

	topUp(): void {
		this.dialog.open(AlarisBalanceDialogComponent);
	}

	confirm(): void {
		this.loading$.next(true);
		let caller: Observable<RPCResult<unknown>> = of({ Success: false });

		switch (this.data.type) {
			case 'PackageRequest':
				caller = this.purchaseService.request({
					Data: {
						type: SubscriptionType.PACK,
						comment: this.comment.value
					}
				});
				break;
			case 'PlanRequest':
				caller = this.purchaseService.request({
					Data: {
						type: SubscriptionType.PLAN,
						comment: this.comment.value
					}
				});
				break;
			case 'SelectPlan':
				caller = this.purchaseService.subscribe({
					Data: {
						Ids: [this.plan.id]
					}
				});
				break;
			case 'ShopNow':
				caller = this.purchaseService.subscribe({
					Data: {
						Ids: [this.package.id as number]
					}
				});
				break;
			default:
				break;
		}

		this.allowedDeactivation.next(false);
		caller.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
			(resp) => {
				this.allowedDeactivation.next(true);
				this.loading$.next(false);
				this.dialogRef.close(resp.Success);
				this.cd.markForCheck();
			}
		);
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}
}
